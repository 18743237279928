export const ACTIVITY_STATUS_TYPES = {
  NOT_STARTED: 1,
  ON_GOING: 2,
  WONT_DO: 3,
  DONE: 4
};

export const ACTIVITY_CONTROLLED_STATUS_TYPES = {
  NOT_ANSWERED: 0,
  NOT_APPROVED: 1,
  APPROVED: 2
}

export const INFO_TYPES = {
  POLICY: 1,
  ROUTINE: 2,
  HANDBOOK: 3,
  LEGAL: 4,
  INSTRUCTION: 5,
  GOAL: 6,
  TRAINING: 7,
  EQUIPMENT: 8,
  MATERIALITY: 9,
  HELP: 10,
  SERVICE: 11,
  NEWS: 12,
  SUPPORT: 13,
  CONTRACTS: 14,
  GDPR_DATA: 15,
  STAKEHOLDER: 16,
  COACH: 17,
  CHEM: 18,
  MANAGER: 19,  //Beslutslogg
  LEGAL2: 20,
  ANALYSIS: 21,
  WORKPROCESS: 22,
  HAZARD: 23,
  DESCRIPTION: 24,
  PROCESSMAP: 25,
  DOCUMENTS: 26,
  ISO_RISK: 27,
  GDPR_SYSTEM: 28,
  WHISTLEBLOWER: 29,
  ACTION_PROGRAM: 30,
  APPROVED_SUPPLIERS: 31,
  AUDIT: 32,
  PERSONELL_ACT: 33,
  HEALT_FACTORS: 34,
  HSEQ_TEMPLATE: 35,
  HSEQ_PROJECT_INFO: 36,
  HSEQ_PROJECT_JOB_DESCRIPTION: 37,
  HSEQ_PROJECT_QUALITY_PLAN: 38,
  HSEQ_PROJECT_POLICY: 39,
  HSEQ_PROJECT_ROUTINE: 40,
  HSEQ_PROJECT_LIST_OF_RELATIVES: 41,
  HSEQ_PROJECT_CHEMICAL_PRODUCTS: 42,
  HSEQ_PROJECT_COMPETENCE_MATRIX: 43,
  HSEQ_PROJECT_ENVIRONMENTAL_PLAN: 44,
  HSEQ_PROJECT_RISK_MANAGEMENT: 45,
  HSEQ_PROJECT_RESCUE_PLAN: 46,
  HSEQ_PROJECT_CONTROL_PLAN: 47,
  HSEQ_PROJECT_OTHER: 48,
  HSEQ_PROJECT_ORGANISATION: 62,
  CULTURE: 49,
  JOB_DESCRIPTION: 50,
  ROPA_DOCUMENTATION: 51,
  ROPA_INFO: 52,
  CRISIS_DOCUMENTATION: 53,
  CRISIS_CLAIM_DOC: 54,
  DIGITAL_DOCUMENTATION: 55,
  FIRE_DOCUMENTATION: 56,
  BUSINESSPLAN: 57,
  SWOT: 58,
  ACTION_PLAN: 59,
  SETTINGS: 60,
  RELATIVES: 61,
  PRODUCT: 62,
  VALUE_CHAIN: 63,
  COMPANY_BINDER: 64,
  FIRE_BUILDING: 65,
  FIRE_FLOOR: 66,
  FIRE_ITEM: 67,
  DOERS_MILESTONE: 68,
  SUSTAINABILITY_REPORT: 69,
  SUSTAINABILITY_REPORT_HISTORY: 70,
  STRATEGIC_GOAL: 71,
  INFORMATION_ASSET: 72,
  INFORMATION_SECURITY_MEASURE: 73,
  CUSTOMERS: 74,
  ESRS_INFO: 10,  // Temp fix. Återanvända help??
};

export const INFO_TYPES_ROUTES = {
  1: ["policy_policy", "quality_policy", "operations_policy", "sustainability_policy"],
  2: ["policy_routine", "operations_routine", "sustainability_routine"],
  3: "employees_manual",
  4: "operations_legislation",
  5: ["policy_instructions", "operations_instructions", "sustainability_instructions", "quality_instructions"],
  6: ["policy_goals", "operations_goals", "sustainability_goals", "quality_goals"],
  7: "marketplace_training",
  8: "other_product",
  9: "materiality",
  10: "help", //might not need to be included
  11: "marketplace_service",
  12: "policy_news",
  13: "support", //doesnt have a module
  14: "policy_contract",
  15: "gdpr_data",
  16: ["sustainability_stakeholder", "quality_stakeholder"],
  17: "marketplace_documentation",
  18: "other_chem",
  19: "policy_manager",
  20: "sustainability_legislation",
  21: "sustainability_documentation",
  22: "other_followup",
  23: ["quality_hazard", " sustainability_hazard"],
  24: "policy_description",
  25: "quality_process_map",
  26: "quality_documentation",
  27: ["policy_iso_risk", "sustainability_iso_risk", "quality_iso_risk"],
  28: "gdpr_documentation",
  29: "", //too many routes
  30: "control_plans",
  31: ["sustainability_approved_supplier", "quality_approved_supplier"],
  32: ["sustainability_audit", "quality_audit"],
  33: "employees_documentation",
  34: "operations_health",
  35: "hseq_template", //needs module updates
  36: "hseq_projects",
  /*HSEQ_PROJECT_JOB_DESCRIPTION:*/ 37: "hseq_projects", //all subobjects currently directly routed to the parent hseq_projects route
  /*HSEQ_PROJECT_QUALITY_PLAN:*/ 38: "hseq_projects",
  /*HSEQ_PROJECT_POLICY:*/ 39: "hseq_projects",
  /*HSEQ_PROJECT_ROUTINE:*/ 40: "hseq_projects",
  /*HSEQ_PROJECT_LIST_OF_RELATIVES:*/ 41: "hseq_projects",
  /*HSEQ_PROJECT_CHEMICAL_PRODUCTS:*/ 42: "hseq_projects",
  /*HSEQ_PROJECT_COMPETENCE_MATRIX:*/ 43: "hseq_projects",
  /*HSEQ_PROJECT_ENVIRONMENTAL_PLAN:*/ 44: "hseq_projects",
  /*HSEQ_PROJECT_RISK_MANAGEMENT:*/ 45: "hseq_projects",
  /*HSEQ_PROJECT_RESCUE_PLAN:*/ 46: "hseq_projects",
  /*HSEQ_PROJECT_CONTROL_PLAN:*/ 47: "hseq_projects",
  /*HSEQ_PROJECT_OTHER:*/ 48: "hseq_projects",
  /*HSEQ_PROJECT_ORGANISATION:*/ 62: "hseq_projects",
  49: "employees_culture",
  50: "employees_jobdescription",
  51: "ropa_documentation",
  52: "ropa_documentation",
  53: "crisis_documentation",
  54: "crisis_documentation",
  55: "digital_documentation",
  56: "fire_documentation",
  57: "policy_businessplan",
  58: "policy_swot",
  64: "policy_companybinder",
  65: "", // FIRE_BUILDING: 65,
  66: "", //FIRE_FLOOR: 66,
  67: "", //FIRE_ITEM: 67,
  68: "", //DOERS_MILESTONE: 68
};

export const INFO_TYPES_ROUTES2 = {
  1: "policy",
  2: "routine",
  3: "manual",
  4: "legislation",
  5: "instruction",
  6: "goal",
  7: "marketplacetraining",
  8: "otherproduct",
  9: "materiality",
  10: "help",
  11: "marketplaceservice",
  12: "policynew",
  14: "contract",
  15: "gdprdata",
  16: "qualityconcern",
  17: "marketplacecoach",
  18: "otherchem",
  19: "manager",
  20: "rule",
  21: "documentation",
  22: "workprocess",
  23: "qualityhazard",
  24: "description",
  25: "processmap",
  26: "documentationmanagement",
  27: "qualityisorisk",
  28: "gdprsystem",
  30: "controlplan",
  31: "sustainabilityapprovedsupplier",
  32: "audit",
  33: "employeedocumentation",
  34: "healthfactor",
  36: "hseqproject",
  49: "culture",
  50: "jobdescription",
  51: "ropadocumentation",
  52: "ropadocumentation",
  53: "crisisdocumentation",
  54: "crisisdocumentation",
  55: "digitaldocumentation",
  56: "firedocumentation",
  57: "businessplan",
  58: "swot",
  59: "controlplan",
  60: "setting",
  61: "relatives",
  62: "qualityproduct",
  63: "requirement",
  64: "companybinder",
  65: "firebuilding",
  66: "firefloor",
  67: "firedevice",
  68: "dobusinessmilestone",
  69: "", //SUSTAINABILITY_REPORT
  70: "", //SUSTAINABILITY_REPORT_HISTORY
  71: "dobusinessgoal", //STRATEGIC_GOAL
  72: "", //INFORMATION_ASSET
  73: "", //INFORMATION_SECURITY_MEASURE
};

export const INFO_STATUS_TYPES = {
  DRAFT: 1,
  AWAITING_REVIEW: 2,
  UNDER_REVIEW: 3,
  APPROVED: 4,
  DONE: 5
};

export const DATAPOINT_STATUS_TYPES = {
  NOT_STARTED: 1,
  ONGOING: 2,
  UNDER_REVIEW: 3,
  APPROVED: 4,
  NOT_REPORTING: 5
};

export const ESRS_DATA_AVAILIBILITY_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5
};



export const INFO_GOAL_FOLLOWUP_TYPES = {
  NONE: 0,
  MANUAL: 1,
  FOLLOWUP: 2,
  CHECKLIST: 3,
  CLAIMS: 4,
  ACTIVITIES: 5,
  PROCENT: 6,
  KPI: 7
};

export const INFO_EMPLOYEE_FOLLOWUP_TYPES = {
  NONE: 0,
  NOTE: 1,
  SURVEY: 2
};

export const HELP_STATUS_TYPES = {
  DRAFT: 1,
  AWAITING_REVIEW: 2,
  UNDER_REVIEW: 3,
  APPROVED: 4,
  DONE: 5
};

export const MILESTONE_STATUS_TYPES = {
  DRAFT: 3,
  ONGOING: 4,
  DONE: 5
};

export const QUESTIONNAIRE_TYPES_ROUTES = {
  1: "employees_survey",
  2: "operations_rounds",
  3: "sustainability_checklist",
  4: "other_workplaces",
  5: "policy_meeting",
  6: "operations_permission",
  BACKUP: 7, //doesn't seem to be used
  8: "policy_risk",
  ONBOARDING: 9, //doesn't use read receipts typically
  CRISIS_MANAGEMENT: 10, //doesn't seem to be used
  CONTINUITY_PLAN: 11, //doesn't seem to be used
  LIQUIDITY_PLAN: 12, //doesn't seem to be used
  ACTIVITY: 13,
  14: "operations_risk",
  15: "employees_ideabox",
  16: "quality_audit",
  17: "operations_claim",
  18: "employees_notes",
  19: "employees_newhire",
  20: "policy_fingerprint",
  21: ["quality_accountability", "sustainability_accountability"],
  PROCESS: 22, //doesn't seem to be used
  23: "operations_investigation",
  24: "operations_accountability",
  25: "operations_think",
  26: "gdpr_checklists",
  27: "employees_external",
  28: ["operations_supplier", "quality_supplier", "sustainability_supplier"],
  29: "employees_bingo",
  30: "quality_surveys",
  31: "quality_checklists",
  32: ["quality_deviations", "sustainability_deviations"],
  33: "hseq_projects", //hseq types doesn't have a route so gets sent to parent object
  34: "hseq_projects"
};

export const QUESTIONNAIRE_TYPES = {
  EMPLOYEE_SURVEY: 1,
  SAFETY_INSPECTION: 2,
  OTHER_CHECKLIST: 3,
  WORKPLACE_INSPECTION: 4,
  MEETING: 5,
  PERMIT: 6,
  BACKUP: 7,
  FINANCIAL: 8,
  ONBOARDING: 9,
  CRISIS_MANAGEMENT: 10,
  CONTINUITY_PLAN: 11, //not used
  LIQUIDITY_PLAN: 12,
  KPI: 13, //update route
  RISK_ASSESSMENT: 14,
  IDEA: 15,
  INTERNAL_INSPECTION: 16,
  OBSERVATION: 17,
  EMPLOYEE_NOTE: 18,
  EMPLOYEE_CHANGE: 19,
  FINGERPRINT: 20,
  SUSTAINABILITY_ACCOUNTABILITY: 21,
  SUBRISK: 22, //update route
  INVESTIGATION: 23,
  ACCOUNTABILITY: 24,
  THINK: 25,
  GDPR: 26,
  EXTERNAL_STAFF: 27,
  SUPPLIER: 28,
  BINGO: 29,
  CUSTOMER_SURVEY: 30,
  QUALITY_FORM: 31,
  DEVIATIONS: 32,
  HSEQ: 33,
  HSEQ_NOTES: 34,
  WELLNESS: 35,
  ROPA_REPORT: 36,
  ROPA_CLAIM: 37,
  EMPLOYEE_ABSENCE: 38,
  CRISIS_NOTE: 39,
  CRISIS_CLAIM: 40,
  DIGITAL_RISK: 41,
  DIGITAL_CHECKLIST: 42,
  DIGITAL_INCIDENT: 43,
  FIRE_CHECKLIST: 44,
  FIRE_EVENT: 45,
  //Add belowe to routes and help
  PRODUCT_LIFE_CYCLE: 46, //Add to product
  WASTE: 47,
  CO2: 48,
  GOAL_FORM: 49,
  KPI2: 50,
  SIGN: 51,
  LOGBOOK: 52,
  DOERS_MEETING: 53,
  REPORT: 54,
  KPI3: 55,
  RISK2: 56,
  SUPPLIER_FORM: 57
};

export const QUESTIONNAIRE_TYPES_ROUTES2 = {
  1: "survey",
  2: "round",
  3: "otherchecklist",
  4: "otherworkplace",
  5: "meeting",
  6: "permission",
  10: "ropachecklists",
  14: "operationsrisk",
  15: "idea",
  16: "audit",
  17: "claim",
  18: "employeenote",
  19: "newhire",
  20: "fingerprint",
  21: "qualityobligation",
  22: "subrisk",
  23: "investigation",
  24: "accountability",
  25: "operationsthink",
  26: "gdpr_checklists",
  27: "external",
  28: "qualitysupplier",
  29: "bingo",
  30: "customersurvey",
  31: "form",
  32: "deviation",
  35: "employeewellness",
  38: "absence",
  39: "crisisdocumentations",
  40: "crisisclaim",
  41: "digitalrisk",
  42: "digitalchecklist",
  43: "digitalclaim",
  44: "firechecklist",
  45: "fireclaim",
  46: "lca",
  47: "waste",
  48: "co2",
  49: "goalform",
  50: "kpiextended",
  51: "sign",
  52: "logbook"
};

export const QUESTIONNAIRE_STATUS_TYPES = {
  DRAFT: 1,
  AWAITING_REVIEW: 2,
  UNDER_REVIEW: 3,
  APPROVED: 4
};

export const HIDDEN_QUESTIONNAIRE_TYPES = [
  QUESTIONNAIRE_TYPES.WORKPLACE_INSPECTION,
  QUESTIONNAIRE_TYPES.BACKUP,
  QUESTIONNAIRE_TYPES.FINANCIAL,
  QUESTIONNAIRE_TYPES.ONBOARDING,
  QUESTIONNAIRE_TYPES.CONTINUITY_PLAN,
  QUESTIONNAIRE_TYPES.LIQUIDITY_PLAN,
  QUESTIONNAIRE_TYPES.PROCESS
];

export const QUESTION_INPUT_TYPES = {
  TEXT: 1,
  NUMBER: 2,
  CHECKBOX: 3,
  HEADER: 4,
  SELECT_LIST: 5,
  RANGE: 6,
  TEXTAREA: 7,
  INFO: 8,
  RADIO_BUTTON: 9,
  LARGE_TEXTAREA: 10,
  DATE: 11,
  SIGN: 12,
  IMAGE: 13,
  FILES: 14,
  SIGN_IMAGE: 15,
  RISK: 16,
  FIRE: 17,
  REPORT: 18,
  RISK2: 19,
  RISK3: 20,
  ROUND: 21,
  FOLLOWUP_TEXT: 22,
  FOLLOWUP_PROTOCOL: 23,
  FOLLOWUP_INFO: 24,
  FOLLOWUP_ACTIVITY: 25,
  FOLLOWUP_QUESTIONNAIRE: 26,
  FOLLOWUP_PROTOCOL2: 27,
  CHECKLIST: 28
};

export const QUESTION_REPORT_TYPES = {
  PREWOE1: 1,
  PREWOE1E: 2,
  PREWOE2: 3,
  PREWOE2E: 4,
  PREWOE2I: 5,
  PREWOE2W: 6,
  PREWOE2C: 7,
  PREWOE3: 8,
  PREWOE3E: 9,
  PREWOE3I: 10,
  PREWOE4: 11,
  PREWOE4E: 12,
  PREWOE5: 13,
  PREWOE6: 14,
  PREWOE6E: 15,
  PREWOE7: 16,
  PREWOE8: 17,
};

export const COMPETENCE_CONSEQUENCE_TYPES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
};

export const CUSTOMER_SUPPLIER_PRIO_TYPES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
};

export const ACTIVITY_CONSEQUENCE_TYPES = {
  LOW: 1,
  MEDIUM: 5,
  HIGH: 10
};

export const SUPPLIER_COMPANY_TYPES = {
  RAW: 1,
  PROCESSING: 2,
  MANUFACTURING: 3,
  WHOLESALE: 4,
  TRANSPORT: 5,
  SERVICE: 6,
  REAL_ESTATE: 7
};

export const REFRESHER_CONSEQUENCE_TYPES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
};

export const THREAT_TYPES = {
  FINANCIAL: 3,
  STRATEGIC: 4,
  OPERATIONAL: 5,
  OTHER: 6
};

export const THREAT_STATUS_TYPES = {
  NOT_STARTED: 1,
  ON_GOING: 2,
  DONE: 3
};

export const THREAT_CONSEQUENCE_TYPES = {
  VERY_LOW: 1,
  LOW: 2,
  MODERATE: 3,
  HIGH: 4,
  VERY_HIGH: 5
};

export const THREAT_PROBABILITY_TYPES = {
  VERY_LOW: 1,
  LOW: 2,
  MODERATE: 3,
  HIGH: 4,
  VERY_HIGH: 5
};

export const THREAT_RISK_TYPES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  CRITICAL: 4
};

export const THREAT_ACTION_ACTION_TYPES = {
  PREVENTIVE: 1,
  DURING: 2,
  AFTER: 3
};

export const VULNERABLE_LEVEL_TYPES = {
  OK: 1,
  PARTLY_OK: 2,
  NOT_OK: 3,
  CRITICAL: 4
};

export const BUSINESS_STATUS_TYPES = {
  OK: 1,
  PARTLY_OK: 2,
  NOT_OK: 3,
};

export const OPPORTUNITY_TYPES = {
  FINANCIAL: 1,
  STRATEGIC: 2,
  OPERATIONAL: 3,
  OTHER: 4
};

export const OPPORTUNITY_CONSEQUENCE_TYPES = {
  VERY_LOW: 1,
  LOW: 2,
  MODERATE: 3,
  HIGH: 4,
  VERY_HIGH: 5
};

export const OPPORTUNITY_PROBABILITY_TYPES = {
  VERY_LOW: 1,
  LOW: 2,
  MODERATE: 3,
  HIGH: 4,
  VERY_HIGH: 5
};

export const OPPORTUNITY_RISK_TYPES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  CRITICAL: 4
};

export const OPPORTUNITY_STATUS_TYPES = {
  NOT_STARTED: 1,
  ON_GOING: 2,
  DONE: 3
};

export const CLAIM_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE6: 6,
  TYPE7: 7,
  TYPE8: 8,
  TYPE9: 9,
  TYPE10: 10,
  TYPE11: 11,
  TYPE12: 12,
  TYPE13: 13,
  TYPE14: 14,
  TYPE15: 15,
  TYPE16: 16,
  TYPE17: 17,
  TYPE18: 18,
  TYPE19: 19,
  TYPE20: 20
};

export const DIGITAL_CLAIM_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const CRISIS_TYPES = {
  TYPE0: 0,
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const CRISIS_IMPACT_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const FIRE_TYPES = {
  TYPE0: 0,
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE6: 6,
  TYPE7: 7,
  TYPE8: 8
};

export const FIRE_IMPACT_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const ROPA_TYPES1 = {
  TYPE0: 0,
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const ROPA_TYPES2 = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5
};

export const ROPA_TYPES21 = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE1: 6,
  TYPE2: 7,
  TYPE3: 8,
  TYPE4: 9,
  TYPE5: 10
};

export const ROPA_TYPES22 = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const ROPA_TYPES23 = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5
};

export const ROPA_TYPES24 = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5
};

export const ROPA_TYPES25 = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE1: 6,
  TYPE2: 7
};

export const CLAIM_STATUS_TYPES = {
  DRAFT: 1,
  REVIEW: 2,
  ANALYSE: 3,
  INVESTIGATION: 4,
  ACT: 5,
  CONTROL: 6,
  DONE: 7,
  OBSOLETE: 8,
  ARCHIVED: 9
};

export const PROTOCOL_STATUS_TYPES = {
  DRAFT: 1,
  REVIEW: 2,
  ANALYSE: 3,
  INVESTIGATION: 4,
  ACT: 5,
  CONTROL: 6,
  DONE: 7,
  OBSOLETE: 8,
  ARCHIVED: 9
};

export const CLAIM_IMPACT_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const DIGITAL_CLAIM_IMPACT_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};

export const ROPA_STATUS_TYPES = {
  DRAFT: 1,
  REVIEW: 2,
  INVESTIGATION: 3,
  DONE: 4
};

export const ROPA_IMPACT_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4
};


export const WASTE_TYPES = {
  PRODUCTION: 1,
  CONSTRUCTION: 2,
  DEMOLITION: 3,
  OTHER: 10
};

export const BUSINESS_TRAVEL_TYPES = {
  BUSINESS_TRAVEL: 1,
  PLANE: 2,
  RAIL: 3,
  PUBLIC: 4,
  CAR: 5,
  HOTEL: 6
};

export const REAL_ESTATE_TYPES = {
  REAL_ESTATE: 1,
  ELECTRICITY: 2,
  HEAT: 3,
  COLD: 4
};

export const COMMUTING_TYPES = {
  COMMUTING: 1,
  BIKE: 2,
  WALK: 3,
  PUBLIC: 4,
  PRIVATE: 5
};

export const SHIPPING_TYPES = {
  SHIPPING: 1,
  ROAD: 2,
  RAIL: 3,
  SEA: 4,
  AIR: 5
};

export const EQUIPMENT_TYPES = {
  VEHICLE: 1,
  MACHINE: 2,
  DOCK: 3,
  FIRE: 4,
  BUILDING: 5,
  FLOOR: 6,
  EQUIPMENT: 7,
  GAGUE: 8,
  OTHER: 10
};

export const EQUIPMENT_TYPES_FIRE = {
  TYP1: 1,
  TYP2: 2,
  TYP3: 3,
  TYP4: 4,
  TYP5: 5,
  TYP10: 10
};

export const SIDEBAR_TYPES = {
  OPERATION: 1,
  QUALITY: 2,
  ENVIROMENT: 3,
  OTHER: 10
};

export const REFRESHER_TYPES = {
  TRAINING: 1,
  MEDICAL_CHECK: 2
};

export const SURVEY_STATUS_TYPES = {
  DRAFT: 1,
  ON_GOING: 2,
  COMPLETED: 3
};

export const SURVEY_TYPES = {
  EMPLOYEE_SURVEY: 0,
  QUALITY_EXTERNAL_SURVEY: 1
}

export const DEVIATION_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE6: 6,
  TYPE7: 7
};

export const IDEA_TYPES = {
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE6: 6,
  TYPE7: 7
};

export const DASHBOARD_WIDGET_TYPES = {
  TEXT: 1,
  LINK: 2,
  FUNCTION: 3
};

export const DASHBOARD_WIDGET_POS = {
  HEADER: 1,
  TOP: 2,
  MAIN: 3,
  BOTTOM: 4,
  FOOTER: 5
};

export const DASHBOARD_WIDGET_FUNCTIONS = {
  WHEEL: 1,
  CROSS: 2,
  NEWS: 3,
  CLAIM_HISTORY: 4,
  CLAIM_LAST: 5,
  GOAL: 6,
  PROCESSMAP: 7,
  GOAL_LIST: 8,
  WORK_PROCESS: 9,
  DEFAULT_REPORTING: 10,
  ACTION_PLAN: 11,
  ISO_OVERVIEW: 12,
  SEARCH: 13,
  ACTIVITIES: 14,
  CRM: 15
};

export const PROCESS_MAP_TYPES = {
  NOT_USED: 0,
  MANAGMENT: 1,
  MAIN: 2,
  SUPPORT: 3,
  EXTERNAL: 4,
  SUB: 5
};

export const VALUE_CHAIN_TYPES = {
  RAW_MATERIAL: 0,
  SUPPLY: 1,
  MANUFACTORING: 2,
  DISTRIBUTION: 3,
  USE: 4,
  WASTE: 5,
  RECYCLING: 6,
};

export const VALUE_CHAIN_SHORT_TYPES = {
  UP: 0,
  INTERNAL: 1,
  DOWN: 2
};

export const TIMEFRAME_TYPES = {
  SHORT: 0,
  SHORT2: 1,
  MEDIUM: 2,
  MEDIUM2: 3,
  LONG: 4,
  LONG2: 5
};

export const ASPECT_TYPES = {
  ASPECT1: 1,
  ASPECT2: 2,
  ASPECT3: 3,
  ASPECT4: 4,
  ASPECT5: 5,
  ASPECT6: 6,
  ASPECT7: 7,
};

export const QBIC_TYPES = {
  VERY_SMALL: 1,
  SMALL: 2,
  BIG: 3,
  VERY_BIG: 4,
};

export const ESRS_CATEGORY_TYPES = {
  MANDATORY: 0,
  CLIMATE_CHANGE: 1,
  POLLOTION: 2,
  WATER: 3,
  BIO: 4,
  CIRCULAR: 5,
  EMPLOYEES: 6,
  SUPPLY_CHAIN_WORKERS: 7,
  COMMUNITIES: 8,
  CUSTOMERS: 9,
  ETHICS: 10,
};

export const ESRS_EXTENDED_CATEGORY_TYPES = {
  TYPE0: 0,
  TYPE1: 1,
  TYPE2: 2,
  TYPE3: 3,
  TYPE4: 4,
  TYPE5: 5,
  TYPE6: 6,
  TYPE7: 7,
  TYPE8: 8,
  TYPE9: 9,
  TYPE10: 10,
  TYPE11: 11,
  TYPE12: 12,
  TYPE13: 13,
  TYPE14: 14,
  TYPE15: 15,
  TYPE16: 16,
  TYPE17: 17,
  TYPE18: 18,
  TYPE19:  19,
  TYPE20: 20,
  TYPE21: 21,
  TYPE22: 22,
  TYPE23: 23,
  TYPE24: 24,
  TYPE25: 25,
  TYPE26: 26,
  TYPE27: 27,
  TYPE28: 28,
  TYPE29: 29,
  TYPE30: 30,
  TYPE31: 31,
  TYPE32: 32,
  TYPE33: 33,
  TYPE34: 34,
  TYPE35: 35,
  TYPE36: 36,
  TYPE37: 37,
  TYPE38: 38,
  TYPE39: 39,
};

