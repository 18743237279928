import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import i18n from "../helpers/language";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login"
  },
  {
    path: "/adsuccess",
    name: "adsuccess",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginFormAdView.vue")
  },
  {
    path: "/loginsuccess",
    name: "loginsuccess",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginSuccessView.vue")
  },
  {
    path: "/",
    name: "",
    redirect: "/login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginView.vue"), // This is a layout view
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginFormView.vue")
      },
      {
        path: "adlogin",
        name: "adlogin",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginFormAdView.vue")
      },
      {
        path: "waiting",
        name: "waiting",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginWaitingView.vue")
      },
      {
        path: "prefortnox",
        name: "prefortnox",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/PreFortnoxView.vue")
      },
      {
        path: "password-reset",
        name: "passwordReset",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/PasswordResetView.vue")
      },
      {
        path: "password-reset/done",
        name: "passwordResetDone",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/PasswordResetDoneView.vue")
      },
      {
        path: "auth/password-reset-confirm/:uid/:token",
        name: "passwordResetConfirm",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/PasswordResetConfirmView.vue")
      },
      {
        path: "password-reset-complete",
        name: "passwordResetComplete",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/PasswordResetCompleteView.vue")
      },
      {
        path: "registrera/:partnerRef?",
        name: "register",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/RegisterView.vue")
      },
      {
        path: "chemlogin",
        name: "chemlogin",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginChemView.vue")
      },
      {
        path: "partnerlogin",
        name: "partnerlogin",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginPartnerView.vue")
      },
      {
        path: "adminlogin",
        name: "adminlogin",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginAdminView.vue")
      },
      {
        path: "login/:partnerRef?",
        name: "customlogin",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginCustomView.vue")
      },
      {
        path: "kmalogin",
        name: "loginHseq",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/LoginHseqView.vue")
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/guest/",
    component: () => import(/* webpackChunkName: "guest" */ "@/views/guest/GuestView.vue"), // This is a layout view
    children: [
      {
        path: "guest/purchase/:id",
        name: "guestpurchase",
        component: () => import(/* webpackChunkName: "guest" */ "@/views/guest/ChemGuestPuchaseView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "guest/confirmation/:id",
        name: "guestuserconfirmation",
        component: () => import(/* webpackChunkName: "guest" */ "@/views/guest/ChemGuestConfirmationView.vue"),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/public",
    component: () => import(/* webpackChunkName: "public" */ "@/views/PublicView.vue"), // This is a layout view
    children: [
      {
        path: "public/home/",
        name: "publichome",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicHomeView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/home2/",
        name: "publichome2",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicHome2View.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/help/",
        name: "publichelp",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicHelpView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/link/",
        name: "publiclink",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicLinkView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/privacypolicy/",
        name: "publicprivacypolicy",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicPrivacyPolicyView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/checklist/:id/:token",
        name: "publicchecklist",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicChecklistView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "public/protocol/:v/:token/:id",
        name: "publicprotocol",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicProtocolView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/questionnaire/:v/:token/:id",
        name: "publicquestionnaire",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicQuestionnaireView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/survey/:v/:token/:id/:invite_token",
        name: "publicsurveyinvite",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicSurveyView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/survey/:v/:token/:id/",
        name: "publicsurvey",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicSurveyView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "public/info/:v/:token/:id",
        name: "publicinfo",
        component: () => import(/* webpackChunkName: "public" */ "@/views/public/PublicInfoView.vue"),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/whistleblower",
    component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/WhistleblowerView.vue"), // This is a layout view
    children: [
      {
        path: "whistleblower/company/:id",
        name: "whistleblowerhome",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/WhistleblowerCompanyHomeView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "whistleblower/login/:id/",
        name: "whistleblowerlogin",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/WhistleblowerLoginView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "whistleblower/help/",
        name: "whistleblowerhelp",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/WhistleblowerHelpView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "whistleblower/privacypolicy/",
        name: "whistleblowerprivacypolicy",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/WhistleblowerPrivacyPolicyView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "whistleblower/faq/:id",
        name: "whistleblowerfrequentlyaskedquestions",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/WhistleblowerFrequentlyAskedQuestions.vue"),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/whistleblowercompanyadmin",
    component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/MainView.vue"),
    children: [
      {
        path: "whistleblowercompanyadmin/activereports",
        name: "whistleblowercompanyadminlist",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/CompanyAdminWhistleblowerListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/whistleblowercompanyadmin/activereports/:id",
        name: "whistleblowercompanyadminreport",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/CompanyAdminWhistleblowerItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "whistleblowercompanyadmin/history",
        name: "whistleblowercompanyadminhistory",
        component: () => import(/* webpackChunkName: "whistleblower" */ "@/views/whistleblower/CompanyAdminWhistleblowerStatisticsView.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/mobile",
    component: () => import(/* webpackChunkName: "mobile" */ "@/views/MobileView.vue"), // This is a layout view
    children: [
      {
        path: "mobile/report",
        name: "mobiledashboard",
        component: () => import(/* webpackChunkName: "mobile" */ "@/views/reports/ReportDashboardView.vue"),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/kma",
    component: () => import(/* webpackChunkName: "hseq" */ "@/views/HseqView.vue"), // This is a layout view
    children: [
      {
        path: "kma",
        name: "hseqdashboard",
        component: () => import(/* webpackChunkName: "hseq" */ "@/views/hseq/HseqProjectListMobileView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/kma/project/:id",
        name: "hseqmobileproject",
        component: () => import(/* webpackChunkName: "hseq" */ "@/views/hseq/HseqProjectItemMobileView.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/participants",
    component: () => import(/* webpackChunkName: "clients" */ "@/views/HseqView.vue"), // This is a layout view
    children: [
      {
        path: "participants",
        name: "clientdashboard",
        component: () => import(/* webpackChunkName: "clients" */ "@/views/clients/ClientListMobileView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/participants/client/:id",
        name: "clientmobile",
        component: () => import(/* webpackChunkName: "clients" */ "@/views/clients/ClientItemMobileView.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    component: () => import(/* webpackChunkName: "main" */ "@/views/MainView.vue"), // This is a layout view
    children: [
      {
        path: "/sandbox", // This is a test view and can be removed!
        name: "sandbox",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/SandboxView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/home/:selectedCompanyId?",
        name: "home",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/HomeView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/companybinder2",
        name: "companybinders2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyCompanyBinderListView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/companybinder2/add",
        name: "companybinder2add",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyCompanyBinderAddView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/companybinder2/:id",
        name: "companybinder2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyCompanyBinderItemView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/legal/legal",
        name: "legals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/legal/legal/add/:id?",
        name: "legaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/legal/legal/:id",
        name: "legal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "protocol/:id",
        name: "protocolview",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/ProtocolRouter.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "info/:id",
        name: "infoview",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/InfoRouter.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/dashboard",
        name: "policydashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_dashboard"
        }
      },
      {
        path: "/policy/stakeholder",
        name: "stakeholders",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderListView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/policy/stakeholder/add/:id?",
        name: "stakeholderadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyStakeholderAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/stakeholder/:id",
        name: "stakeholder",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyStakeholderItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/goals",
        name: "goals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/goals/add/:id?",
        name: "goaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/goals/report/:id",
        name: "goalreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/goals/:id",
        name: "goal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/process",
        name: "policyprocesses",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyProcessListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/process/add/:id?",
        name: "policyprocessadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyProcessAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/process/:id",
        name: "policyprocess",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyProcessItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/organization",
        name: "policyorganization",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyOrganizationView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_organisation"
        }
      },
      {
        path: "/policy/planning",
        name: "policyplanning",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPlanningListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_planning"
        }
      },
      {
        path: "/policy/planning/add/:id?",
        name: "policyplanningadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPlanningAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_planning"
        }
      },
      {
        path: "/policy/planning/:id",
        name: "policyplan",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPlanningItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_planning"
        }
      },
      {
        path: "/policy/risk",
        name: "policyrisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/threat",
        name: "policyriskthreatdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskThreatDasboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/threat/:threatTypeId",
        name: "policyriskthreats",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskThreatListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/threat/:threatTypeId/add",
        name: "policyriskthreatadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskThreatAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/threat/:threatTypeId/:threatId",
        name: "policyriskthreat",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskThreatItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/opportunity",
        name: "policyriskopportunitydashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskOpportunityDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/opportunity/:opportunityTypeId",
        name: "policyriskopportunitys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskOpportunityListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/opportunity/:opportunityTypeId/add",
        name: "policyriskopportunityadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskOpportunityAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/opportunity/:opportunityTypeId/:opportunityId",
        name: "policyriskopportunity",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskOpportunityItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/competence/home",
        name: "policyriskfunctions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskFunctionDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/competence/",
        name: "policyriskcompetences",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskCompetenceListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/competence/:id",
        name: "policyriskcompetence",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskCompetenceItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/competenceadd",
        name: "policyriskcompetenceadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskCompetenceAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/external/",
        name: "policyriskexternals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskExternalListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/external/:id",
        name: "policyriskexternal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskExternalItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/externaladd",
        name: "policyriskexternaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskExternalAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },

      {
        path: "/policy/risk/economics/",
        name: "policyriskeconomicsdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskEconomicsDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/financials/",
        name: "policyriskfinancials",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskFinancialListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/financials/:id",
        name: "policyriskfinancial",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskFinancialItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/financialsadd/:id?",
        name: "policyriskfinancialadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskFinancialAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/liquidity/",
        name: "policyriskliquiditys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskLiquidityListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/liquidity/:id",
        name: "policyriskliquidity",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskLiquidityItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/risk/liquidityadd/:id?",
        name: "policyriskliquidityadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRiskLiquidityAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_risk"
        }
      },
      {
        path: "/policy/control",
        name: "policycontrols",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyControlListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/control/add",
        name: "policycontroladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyControlAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/control/:id",
        name: "policycontrol",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyControlItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/policy/isorisks",
        name: "policyisorisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_iso_risk"
        }
      },
      {
        path: "/policy/isorisks/add",
        name: "policyisoriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_iso_risk"
        }
      },
      {
        path: "/policy/isorisks/:id",
        name: "policyisorisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_iso_risk"
        }
      },
      {
        path: "/policy/policy",
        name: "policys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_policy"
        }
      },
      {
        path: "/policy/policy/add",
        name: "policypolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_policy"
        }
      },
      {
        path: "/policy/policy/:id",
        name: "policy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_policy"
        }
      },
      {
        path: "/policy/instructions",
        name: "instructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_instructions"
        }
      },
      {
        path: "/policy/instructions/add",
        name: "instructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_instructions"
        }
      },
      {
        path: "/policy/instructions/:id",
        name: "instruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_instructions"
        }
      },
      {
        path: "/policy/routine",
        name: "routines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/routine/add",
        name: "routineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/routine/:id",
        name: "routine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/news",
        name: "policynews",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyNewsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_news"
        }
      },
      {
        path: "/policy/news/add",
        name: "policynewsadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyNewsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_news"
        }
      },
      {
        path: "/policy/news/:id",
        name: "policynew",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyNewsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_news"
        }
      },
      {
        path: "/policy/contract",
        name: "contracts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyContractListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_contract"
        }
      },
      {
        path: "/policy/contract/add/:id?",
        name: "contractadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyContractAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_contract"
        }
      },
      {
        path: "/policy/contract/:id",
        name: "contract",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyContractItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_contract"
        }
      },
      {
        path: "/policy/fingerprint",
        name: "fingerprints",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/policy/fingerprint/add/:id?",
        name: "fingerprintadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/policy/fingerprint/:id",
        name: "fingerprint",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/policy/report/:type/:id2",
        name: "protocolreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyProtocolReportView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/policy/personreport/:type/:personId",
        name: "protocolpersonreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyProtocolPersonReportView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/policy/reports/:type",
        name: "protocolsreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyProtocolsReportView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/policy/meeting",
        name: "meetings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyMeetingListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_meeting"
        }
      },
      {
        path: "/policy/meeting/add/:id?",
        name: "meetingadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyMeetingAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_meeting"
        }
      },
      {
        path: "/policy/meeting/add2/:oldId",
        name: "meetingadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyMeetingAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_meeting"
        }
      },
      {
        path: "/policy/meeting/:id",
        name: "meeting",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyMeetingItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_meeting"
        }
      },
      {
        path: "/policy/kpi",
        name: "kpis",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/kpi/list/:id",
        name: "kpilist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/kpi/add/:id?",
        name: "kpiadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/kpi/add/:id/:id2",
        name: "kpiadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/kpi/:id",
        name: "kpi",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/kpiextended/add/:id?",
        name: "kpiextendedadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIExtendedAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/policy/kpiextended/:id",
        name: "kpiextended",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyKPIExtendedItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },

      {
        path: "/policy/manager",
        name: "managers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyManagerListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_manager"
        }
      },
      {
        path: "/policy/manager/add",
        name: "manageradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyManagerAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_manager"
        }
      },
      {
        path: "/policy/manager/:id",
        name: "manager",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyManagerItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_manager"
        }
      },
      {
        path: "/policy/description",
        name: "descriptions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyDescriptionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_description"
        }
      },
      {
        path: "/policy/description/add",
        name: "descriptionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyDescriptionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_description"
        }
      },
      {
        path: "/policy/description/report",
        name: "descriptionreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyDescriptionReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_description"
        }
      },
      {
        path: "/policy/description/report2",
        name: "descriptionreport2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyDescriptionReportView2.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_description"
        }
      },
      {
        path: "/policy/description/:id",
        name: "description",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyDescriptionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_description"
        }
      },
      {
        path: "/policy/supplier",
        name: "suppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier"
        }
      },
      {
        path: "/policy/supplier/add/:id?",
        name: "supplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier"
        }
      },
      {
        path: "/policy/supplier/add2/:id2?",
        name: "supplieradd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier"
        }
      },
      {
        path: "/policy/supplier/:id",
        name: "supplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier"
        }
      },
      {
        path: "/policy/businessplan",
        name: "businessplans",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyBusinessPlanListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/businessplan/add",
        name: "businessplanadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyBusinessPlanAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/businessplan/:id",
        name: "businessplan",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyBusinessPlanItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/swot",
        name: "swots",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySWOTListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/swot/add",
        name: "swotadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySWOTAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/swot/:id",
        name: "swot",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySWOTItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/policy/companybinder",
        name: "companybinders",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyCompanyBinderListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_companybinder"
        }
      },
      {
        path: "/policy/companybinder/add",
        name: "companybinderadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyCompanyBinderAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_companybinder"
        }
      },
      {
        path: "/policy/companybinder/:id",
        name: "companybinder",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyCompanyBinderItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_companybinder"
        }
      },
      {
        path: "/gdpr/data",
        name: "gdprdatas",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprDataListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_data"
        }
      },
      {
        path: "/gdpr/data/add",
        name: "gdprdataadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprDataAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_data"
        }
      },
      {
        path: "/gdpr/data/:id",
        name: "gdprdata",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprDataItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_data"
        }
      },
      {
        path: "/gdpr/documentation",
        name: "gdprsystems",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprSystemListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_documentation"
        }
      },
      {
        path: "/gdpr/documentation/add",
        name: "gdprsystemadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprSystemAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_documentation"
        }
      },
      {
        path: "/gdpr/documentation/:id",
        name: "gdprsystem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprSystemItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_documentation"
        }
      },

      {
        path: "/gdpr/checklists",
        name: "gdprchecklists",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprChecklistListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_checklists"
        }
      },
      {
        path: "/gdpr/checklists/add/:id?",
        name: "gdprchecklistadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprChecklistAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_checklists"
        }
      },
      {
        path: "/gdpr/checklists/:id",
        name: "gdprchecklist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/gdpr/GdprChecklistItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "gdpr_checklists"
        }
      },
      {
        path: "/hseq/template",
        name: "hseqtemplates",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqTemplateListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/template/add",
        name: "hseqtemplateadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqTemplateAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/template/:id",
        name: "hseqtemplate",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqTemplateItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/protocolsettings",
        name: "protocolsettings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqProtocolSettingsView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/projects",
        name: "hseqprojects",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqProjectListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/projects/add",
        name: "hseqprojectadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqProjectAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/projects/:id",
        name: "hseqproject",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/HseqProjectItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/hseq/projects_policy/:id",
        name: "hseqprojectpolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/hseq/components/InfoFormProject.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "hseq_projects"
        }
      },
      {
        path: "/clients/template",
        name: "clienttemplates",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientTemplateListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/clients/template/add",
        name: "clienttemplateadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientTemplateAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/clients/template/:id",
        name: "clienttemplate",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientTemplateItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/clients/protocolsettings",
        name: "clientprotocolsettings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientProtocolSettingsView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/clients/participants",
        name: "clients",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/clients/participants/add",
        name: "clientadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/clients/participants/:id",
        name: "client",
        component: () => import(/* webpackChunkName: "main" */ "@/views/clients/ClientItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "clients"
        }
      },
      {
        path: "/marketplace/documents",
        name: "marketplacecoaches",
        component: () => import(/* webpackChunkName: "main" */ "@/views/marketplace/MarketplaceCoachListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "marketplace_documentation"
        }
      },
      {
        path: "/marketplace/documents/:id",
        name: "marketplacecoach",
        component: () => import(/* webpackChunkName: "main" */ "@/views/marketplace/MarketplaceCoachItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "marketplace_documentation"
        }
      },
      {
        path: "/marketplace/training",
        name: "marketplacetrainings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/marketplace/MarketplaceTrainingListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "marketplace_training"
        }
      },
      {
        path: "/marketplace/training/:id",
        name: "marketplacetraining",
        component: () => import(/* webpackChunkName: "main" */ "@/views/marketplace/MarketplaceTrainingItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "marketplace_training"
        }
      },
      {
        path: "/marketplace/service",
        name: "marketplaceservices",
        component: () => import(/* webpackChunkName: "main" */ "@/views/marketplace/MarketplaceServiceListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "marketplace_service"
        }
      },
      {
        path: "/marketplace/service/:id",
        name: "marketplaceservice",
        component: () => import(/* webpackChunkName: "main" */ "@/views/marketplace/MarketplaceServiceItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "marketplace_service"
        }
      },
      {
        path: "/projects/active",
        name: "projects",
        component: () => import(/* webpackChunkName: "main" */ "@/views/projects/ProjectsActiveListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/projects/project/add",
        name: "projectadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/projects/ProjectsProjectAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/projects/project/:id",
        name: "project",
        component: () => import(/* webpackChunkName: "main" */ "@/views/projects/ProjectsProjectItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/projects/all",
        name: "projectsall",
        component: () => import(/* webpackChunkName: "main" */ "@/views/projects/ProjectsAllListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees",
        name: "employeedashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/appraisal",
        name: "appraisals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAppraisalListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/appraisal/:id",
        name: "appraisal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAppraisalItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/contact",
        name: "contacts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeContactListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/contact/:id",
        name: "contact",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeContactItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/culture",
        name: "cultures",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCultureListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_company_culture"
        }
      },
      {
        path: "/employees/culture/add/:id?",
        name: "cultureadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCultureAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_company_culture"
        }
      },
      {
        path: "/employees/culture/:id",
        name: "culture",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCultureItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_company_culture"
        }
      },
      {
        path: "/employees/rehabs",
        name: "rehabs",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeRehabListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_rehabs"
        }
      },
      {
        path: "/employees/rehabs/:id",
        name: "rehab",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeRehabItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_rehabs"
        }
      },
      {
        path: "/employees/wellness",
        name: "wellness",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeWellnessListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/wellness/add/:id?",
        name: "employeewellnessadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeWellnessAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/wellness/report",
        name: "employeewellnessreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeWellnessReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_notes"
        }
      },
      {
        path: "/employees/wellness/:id",
        name: "employeewellness",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeWellnessItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/absence",
        name: "absences",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAbsenceListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_absence"
        }
      },
      {
        path: "/employees/absence/add/:id?",
        name: "absenceadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAbsenceAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_absence"
        }
      },
      {
        path: "/employees/absence/report",
        name: "absencereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAbsenceReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_absence"
        }
      },
      {
        path: "/employees/absence/report2",
        name: "absencereport2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAbsenceReport2View.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_absence"
        }
      },
      {
        path: "/employees/absence/:id",
        name: "absence",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeAbsenceItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_absence"
        }
      },

      {
        path: "/employees/newhire",
        name: "newhires",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNewHireListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_newhire"
        }
      },
      {
        path: "/employees/newhire/add/:id?",
        name: "newhireadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNewHireAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_newhire"
        }
      },
      {
        path: "/employees/newhire/:id",
        name: "newhire",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNewHireItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_newhire"
        }
      },
      {
        path: "/employees/external",
        name: "externals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeExternalListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_external"
        }
      },
      {
        path: "/employees/external/add/:id?",
        name: "externaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeExternalAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_external"
        }
      },
      {
        path: "/employees/external/:id",
        name: "external",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeExternalItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_external"
        }
      },
      {
        path: "/employees/oldhire",
        name: "oldhires",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeOldHireListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/oldhire/add",
        name: "oldhireadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeOldHireAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/oldhire/:id",
        name: "oldhire",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeOldHireItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/ideabox",
        name: "ideabox",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/employees/ideabox/add/:id?",
        name: "ideaadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/employees/ideabox/add2/:id2?",
        name: "ideaadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/employees/ideabox/add3/:id3?",
        name: "ideaadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/employees/ideabox/report/",
        name: "ideareport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/employees/ideabox/:id",
        name: "idea",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/employees/timereport",
        name: "timereports",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeTimeReportListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/timereport/:id",
        name: "timereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeTimeReportItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/employees/medicalcheck",
        name: "medicalchecks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeMedicalCheckListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_medicalcheck"
        }
      },
      {
        path: "/employees/medicalcheck/add",
        name: "medicalcheckadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeMedicalCheckAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_medicalcheck"
        }
      },
      {
        path: "/employees/medicalcheck/report",
        name: "medicalcheckreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeMedicalCheckReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_medicalcheck"
        }
      },
      {
        path: "/employees/medicalcheck/:id",
        name: "medicalcheck",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeMedicalCheckItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_medicalcheck"
        }
      },
      {
        path: "/employees/training",
        name: "trainings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeTrainingListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_training"
        }
      },
      {
        path: "/employees/training/add",
        name: "trainingadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeTrainingAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_training"
        }
      },
      {
        path: "/employees/training/report",
        name: "trainingreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeTrainingReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_training"
        }
      },
      {
        path: "/employees/training/:id",
        name: "training",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeTrainingItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_training"
        }
      },
      {
        path: "/employees/notes",
        name: "employeenotes",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNotesListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_notes"
        }
      },
      {
        path: "/employees/notes/add/:id?",
        name: "employeenoteadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNotesAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_notes"
        }
      },
      {
        path: "/employees/notes/report",
        name: "employeenotereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNotesReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_notes"
        }
      },
      {
        path: "/employees/notes/:id",
        name: "employeenote",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeNotesItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_notes"
        }
      },
      {
        path: "/employees/survey",
        name: "surveys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/survey/add/:id?",
        name: "surveyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/survey/report",
        name: "surveypulsreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyReportListView"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/survey/report/:id",
        name: "surveyreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/survey/history/:id",
        name: "surveyhistory",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyBundleHistoryView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/survey/:id",
        name: "survey",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/surveybundle/:id?",
        name: "surveybundle",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyBundleItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/surveybundle/add",
        name: "surveybundleadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyBundleAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/surveybundles",
        name: "surveybundles",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeSurveyBundleListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/survey/:id/:token",
        name: "survey2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/questionnaire/SurveyView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_survey"
        }
      },
      {
        path: "/employees/manual",
        name: "manuals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeManualListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_manual"
        }
      },
      {
        path: "/employees/manual/add",
        name: "manualadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeManualAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_manual"
        }
      },
      {
        path: "/employees/manual/:id",
        name: "manual",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeManualItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_manual"
        }
      },
      {
        path: "/employees/people/",
        name: "employeepersons",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeePersonListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_people"
        }
      },
      {
        path: "/employees/people/add",
        name: "employeepersonadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeePersonAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_people"
        }
      },
      {
        path: "/employees/people/:id",
        name: "employeeperson",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeePersonItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_people"
        }
      },
      {
        path: "/employees/competence/",
        name: "employeecompetences",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCompetenceListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_competence"
        }
      },
      {
        path: "/employees/competence/report",
        name: "employeecompetencereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCompetenceReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_competence"
        }
      },
      {
        path: "/employees/competence/report2",
        name: "employeecompetencereport2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCompetenceReport2View.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_competence"
        }
      },
      {
        path: "/employees/competence/add",
        name: "employeecompetenceadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCompetenceAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_competence"
        }
      },
      {
        path: "/employees/competence/:id",
        name: "employeecompetence",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeCompetenceItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_competence"
        }
      },
      {
        path: "/employees/bingo",
        name: "bingos",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeBingoListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_bingo"
        }
      },
      {
        path: "/employees/bingo/add/:id?",
        name: "bingoadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeBingoAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_bingo"
        }
      },
      {
        path: "/employees/bingo/:id",
        name: "bingo",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeBingoItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_bingo"
        }
      },
      {
        path: "/employees/documentation",
        name: "employeedocumentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeDocumentationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/documentation/add",
        name: "employeedocumentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeDocumentationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/documentation/:id",
        name: "employeedocumentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeDocumentationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/jobdescription",
        name: "jobdescriptions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeJobDescriptionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/jobdescription/add",
        name: "jobdescriptionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeJobDescriptionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/jobdescription/checklist/add/:id",
        name: "jobdescriptionchecklistadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeJobDescriptionChecklistAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/jobdescription/checklist/:id",
        name: "jobdescriptionchecklist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeJobDescriptionChecklistItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/employees/jobdescription/:id",
        name: "jobdescription",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeJobDescriptionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_documentation"
        }
      },
      {
        path: "/operations/home",
        name: "operationdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/risk",
        name: "operationsrisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRiskList.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/risk/add/:id?",
        name: "operationsriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/risk/add2/:id2?/:id?",
        name: "operationsriskadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/risk/add3/:id2?/:id?",
        name: "operationsriskadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRiskAdd2View.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/risk/:id",
        name: "operationsrisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRiskItem.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/isorisks",
        name: "operationsisorisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/isorisks/add",
        name: "operationsisoriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/isorisks/:id",
        name: "operationsisorisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_risk"
        }
      },
      {
        path: "/operations/place",
        name: "placedashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationPlaceList.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/place/add",
        name: "placeadditem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationPlaceAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/place/:id",
        name: "placeitem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationPlaceItem.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/legislation",
        name: "legislations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_legislation"
        }
      },
      {
        path: "/operations/legislation/add/:id?",
        name: "legislationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_legislation"
        }
      },
      {
        path: "/operations/legislation/:id",
        name: "legislation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_legislation"
        }
      },
      {
        path: "/operations/investigation",
        name: "investigations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationInvestigationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_investigation"
        }
      },
      {
        path: "/operations/investigation/add/:id?",
        name: "investigationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationInvestigationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_investigation"
        }
      },
      {
        path: "/operations/investigation/:id",
        name: "investigation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationInvestigationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_investigation"
        }
      },
      {
        path: "/operations/accountability",
        name: "accountabilitys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationAccountabilityListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_accountability"
        }
      },
      {
        path: "/operations/accountability/report/",
        name: "accountabilityreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationAccountabilityReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_accountability"
        }
      },
      {
        path: "/operations/accountability/add/:id?",
        name: "accountabilityadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationAccountabilityAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_accountability"
        }
      },
      {
        path: "/operations/accountability/:id",
        name: "accountability",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationAccountabilityItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_accountability"
        }
      },
      {
        path: "/operations/permission",
        name: "permissions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationPermissionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_permission"
        }
      },
      {
        path: "/operations/permission/add/:id?",
        name: "permissionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationPermissionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_permission"
        }
      },
      {
        path: "/operations/permission/:id",
        name: "permission",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationPermissionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_permission"
        }
      },
      {
        path: "/operations/rounds",
        name: "rounds",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRoundListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_rounds"
        }
      },
      {
        path: "/operations/rounds/add/:id?",
        name: "roundadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRoundAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_rounds"
        }
      },
      {
        path: "/operations/rounds/add2/:id2?",
        name: "roundadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRoundAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_rounds"
        }
      },
      {
        path: "/operations/rounds/:id",
        name: "round",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationRoundItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_rounds"
        }
      },
      {
        path: "/operations/claim",
        name: "claims",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationClaimListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_claim"
        }
      },
      {
        path: "/operations/claim/add/:id?",
        name: "claimadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationClaimAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_claim"
        }
      },
      {
        path: "/operations/claim/add2/:id2?",
        name: "claimadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationClaimAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_claim"
        }
      },
      {
        path: "/operations/claim/add3/:id3?",
        name: "claimadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationClaimAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_claim"
        }
      },
      {
        path: "/operations/claim/report",
        name: "claimreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationClaimReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_claim"
        }
      },
      {
        path: "/operations/claim/:id",
        name: "claim",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationClaimItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_claim"
        }
      },
      {
        path: "/operations/think",
        name: "operationsthinks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationThinkList.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_think"
        }
      },
      {
        path: "/operations/think/add/:id?",
        name: "operationsthinkadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationThinkAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_think"
        }
      },
      {
        path: "/operations/think/:id",
        name: "operationsthink",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationThinkItem.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_think"
        }
      },

      {
        path: "/operations/health",
        name: "healthfactors",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationHealthFactorsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_health"
        }
      },
      {
        path: "/operations/health/add/:id?",
        name: "healthfactoradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationHealthFactorsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_health"
        }
      },
      {
        path: "/operations/health/:id",
        name: "healthfactor",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationHealthFactorsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_health"
        }
      },

      {
        path: "/operations/policy",
        name: "operationspolicys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_policy"
        }
      },
      {
        path: "/operations/policy/add",
        name: "operationspolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_policy"
        }
      },
      {
        path: "/operations/policy/:id",
        name: "operationspolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_policy"
        }
      },
      {
        path: "/operations/instructions",
        name: "operationsinstructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_instructions"
        }
      },
      {
        path: "/operations/instructions/add",
        name: "operationsinstructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_instructions"
        }
      },
      {
        path: "/operations/instructions/:id",
        name: "operationsinstruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_instructions"
        }
      },
      {
        path: "/operations/routine",
        name: "operationsroutines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_routine"
        }
      },
      {
        path: "/operations/routine/add",
        name: "operationsroutineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_routine"
        }
      },
      {
        path: "/operations/routine/:id",
        name: "operationsroutine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_routine"
        }
      },
      {
        path: "/operations/goals",
        name: "operationsgoals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_goals"
        }
      },
      {
        path: "/operations/goals/add",
        name: "operationsgoaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_goals"
        }
      },
      {
        path: "/operations/goals/:id",
        name: "operationsgoal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_goals"
        }
      },
      {
        path: "/operations/supplier",
        name: "operationssuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/supplier/add/:id?",
        name: "operationssupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/supplier/:id",
        name: "operationssupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/hazard",
        name: "operationshazards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/hazard/add",
        name: "operationshazardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/hazard/:id",
        name: "operationshazard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/audit",
        name: "operationsaudits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/audit/add",
        name: "operationsauditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/audit/:id",
        name: "operationsaudit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "operations_supplier_control"
        }
      },
      {
        path: "/operations/rules",
        name: "operationsrules",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/rules/add/:id?",
        name: "operationsruleadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/rules/:id",
        name: "operationsrule",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/operations/ideabox",
        name: "operationsideabox",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/operations/ideabox/add/:id?",
        name: "operationsideaadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/operations/ideabox/add2/:id2?",
        name: "operationsideaadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/operations/ideabox/report/",
        name: "operationsideareport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/operations/ideabox/:id",
        name: "operationsidea",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/safety/report",
        name: "ropareport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaReportView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/analysis",
        name: "ropaanalysis",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaAnalysisListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/analysis/add/:id",
        name: "ropaanalysadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaAnalysisAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/analysis/:id",
        name: "ropaanalys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaAnalysisItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/claim",
        name: "ropaclaims",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaClaimListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/claim/add",
        name: "ropaclaimadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaClaimAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/claim/history",
        name: "ropahistory",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaHistoryListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/claim/history/:id",
        name: "ropaclaim2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaClaimItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/claim/:id",
        name: "ropaclaim",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaClaimItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/documentation",
        name: "ropadocumentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaDocumentationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/documentation/add",
        name: "ropadocumentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaDocumentationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/documentation/:id",
        name: "ropadocumentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaDocumentationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/checklists",
        name: "ropachecklists",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaChecklistListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/checklists/add/:id?",
        name: "ropachecklistadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaChecklistAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/checklists/:id",
        name: "ropachecklist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/ropa/RopaChecklistItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      ,
      {
        path: "/safety/hazard",
        name: "safetyhazards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/hazard/add",
        name: "safetyhazardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/hazard/:id",
        name: "safetyhazard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/audit",
        name: "safetyaudits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/audit/add",
        name: "safetyauditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/audit/:id",
        name: "safetyaudit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/claim",
        name: "crisisclaims",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisClaimListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/claim/add",
        name: "crisisclaimadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisClaimAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/claim/:id",
        name: "crisisclaim",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisClaimItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/history",
        name: "crisishistory",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisHistoryListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/documentation",
        name: "crisisdocumentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisDocumentationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/documentation/add",
        name: "crisisdocumentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisDocumentationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/crisis/documentation/:id",
        name: "crisisdocumentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisDocumentationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/business/businessplan",
        name: "dobusinessplans",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessPlanListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/businessplan/add",
        name: "dobusinessplanadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessPlanAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/businessplan/:id",
        name: "dobusinessplan",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessPlanItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/followup/businessboard",
        name: "dobusinessboards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessBoardListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/followup/businessboard/:id",
        name: "dobusinessboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessBoardItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/followup/dashboard",
        name: "dobusinessfollowupdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessFollowupDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/dashboard",
        name: "dobusinessdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/goals",
        name: "dobusinessgoals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessGoalsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/goals/add/:id?",
        name: "dobusinessgoaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessGoalsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/goals/report/:id",
        name: "dobusinessgoalreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessGoalsReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/goals/:id",
        name: "dobusinessgoal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessGoalsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },

      {
        path: "/business/milestones",
        name: "dobusinessmilestones",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMilestonesListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/milestones/add/:id?",
        name: "dobusinessmilestoneadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMilestonesAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/milestones/report/:id",
        name: "dobusinessmilestonereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMilestonesReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/milestones/:id",
        name: "dobusinessmilestone",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMilestonesItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/swot",
        name: "dobusinessswots",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessPlanSwotListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_basic"
        }
      },
      {
        path: "/business/swot/add/:id?",
        name: "dobusinessswotadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySWOTAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/business/swot/:id",
        name: "dobusinessswot",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySWOTItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_routine"
        }
      },
      {
        path: "/business/meeting",
        name: "dobusinessmeetings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMeetingListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_advanced"
        }
      },
      {
        path: "/business/meeting/add/:id?",
        name: "dobusinessmeetingadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMeetingAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_advanced"
        }
      },
      {
        path: "/business/meeting/add2/:id2?",
        name: "dobusinessmeetingadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMeetingAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_advanced"
        }
      },
      {
        path: "/business/meeting/add3/:id2/:oldId",
        name: "dobusinessmeetingadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMeetingAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_advanced"
        }
      },
      {
        path: "/business/meeting/:id",
        name: "dobusinessmeeting",
        component: () => import(/* webpackChunkName: "main" */ "@/views/business/BusinessMeetingItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "doers_advanced"
        }
      },
      {
        path: "/business/fingerprint",
        name: "dobusinessfingerprints",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/business/fingerprint/add/:id?",
        name: "dobusinessfingerprintadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/business/fingerprint/add2/:id2?",
        name: "dobusinessfingerprintadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/business/fingerprint/:id",
        name: "dobusinessfingerprint",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyFingerprintItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_fingerprint"
        }
      },
      {
        path: "/safety/policy",
        name: "crisispolicys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/policy/add",
        name: "crisispolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/policy/:id",
        name: "crisispolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/instructions",
        name: "crisisinstructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/instructions/add",
        name: "crisisinstructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/instructions/:id",
        name: "crisisinstruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/routine",
        name: "crisisroutines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/routine/add",
        name: "crisisroutineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/routine/:id",
        name: "crisisroutine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/risks",
        name: "crisisrisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/risks/add",
        name: "crisisriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/safety/risks/:id",
        name: "crisisrisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/crisis/CrisisRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/documentation",
        name: "digitaldocumentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalDocumentationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/documentation/add",
        name: "digitaldocumentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalDocumentationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/documentation/:id",
        name: "digitaldocumentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalDocumentationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/asset",
        name: "digitalassets",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalAssetListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/asset/add",
        name: "digitalassetadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalAssetAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/asset/:id",
        name: "digitalasset",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalAssetItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/measure",
        name: "digitalmeasures",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalMeasureListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/measure/add",
        name: "digitalmeasureadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalMeasureAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/measure/:id",
        name: "digitalmeasure",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalMeasureItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/policy",
        name: "digitalpolicys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/policy/add",
        name: "digitalpolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/policy/:id",
        name: "digitalpolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/instructions",
        name: "digitalinstructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/instructions/add",
        name: "digitalinstructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/instructions/:id",
        name: "digitalinstruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/routine",
        name: "digitalroutines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/routine/add",
        name: "digitalroutineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/routine/:id",
        name: "digitalroutine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/risks",
        name: "digitalrisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/risks/add",
        name: "digitalriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/risks/add2/:id2?/:id?",
        name: "digitalriskadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/risks/:id",
        name: "digitalrisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/claim",
        name: "digitalclaims",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalClaimListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/claim/add/:id?",
        name: "digitalclaimadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalClaimAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/claim/add2/:id2?/:id?",
        name: "digitalclaimadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalClaimAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/claim/report",
        name: "digitalclaimreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalClaimReportView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/claim/:id",
        name: "digitalclaim",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalClaimItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/checklists",
        name: "digitalchecklists",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalChecklistListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/checklist/add/:id?",
        name: "digitalchecklistadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalChecklistAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/checklist/:id",
        name: "digitalchecklist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/digital/DigitalChecklistItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/hazard",
        name: "digitalhazards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/hazard/add",
        name: "digitalhazardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/hazard/:id",
        name: "digitalhazard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/audit",
        name: "digitalaudits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/audit/add",
        name: "digitalauditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/audit/:id",
        name: "digitalaudit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      ,
      {
        path: "/digital/legislation",
        name: "digitalrules",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/legislation/add/:id?",
        name: "digitalruleadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/legislation/:id",
        name: "digitalrule",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/accountability",
        name: "digitalobligations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/accountability/add/:id?",
        name: "digitalobligationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/accountability/:id",
        name: "digitalobligation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/isorisks",
        name: "digitalisorisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/isorisks/add",
        name: "digitalisoriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/isorisks/:id",
        name: "digitalisorisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/goals",
        name: "digitalgoals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/goals/add",
        name: "digitalgoaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/goals/:id",
        name: "digitalgoal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/supplier",
        name: "digitalsuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/supplier/add/:id?",
        name: "digitalsupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/supplier/:id",
        name: "digitalsupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "/digital/stakeholder",
        name: "digitalconcerns",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/stakeholder/add",
        name: "digitalconcernadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/stakeholder/:id",
        name: "digitalconcern",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyStakeholderItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/approved_supplier",
        name: "digitalapprovedsuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/approved_supplier/report",
        name: "digitalapprovedsupplierreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/digital/approved_supplier/add",
        name: "digitalapprovedsupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/digital/approved_supplier/:id",
        name: "digitalapprovedsupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/documentation",
        name: "firedocumentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireDocumentationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/documentation/add",
        name: "firedocumentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireDocumentationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/documentation/:id",
        name: "firedocumentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireDocumentationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/policy",
        name: "firepolicys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/policy/add",
        name: "firepolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/policy/:id",
        name: "firepolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/instructions",
        name: "fireinstructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/instructions/add",
        name: "fireinstructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/instructions/:id",
        name: "fireinstruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/routine",
        name: "fireroutines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/routine/add",
        name: "fireroutineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/routine/:id",
        name: "fireroutine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/risks",
        name: "firerisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/risks/add",
        name: "fireriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/risks/:id",
        name: "firerisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/claim",
        name: "fireclaims",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireClaimListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/claim/add/:id?",
        name: "fireclaimadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireClaimAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/claim/add2/:id2?",
        name: "fireclaimadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireClaimAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/claim/report",
        name: "fireclaimreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireClaimReportView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/claim/:id",
        name: "fireclaim",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireClaimItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/checklists",
        name: "firechecklists",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireChecklistListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/checklists/add/:id?",
        name: "firechecklistadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireChecklistAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/checklists/add2/:id2?/:id?",
        name: "firechecklistadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireChecklistAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/checklists/:id/:id2?",
        name: "firechecklist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireChecklistItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/rounds",
        name: "firerounds",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRoundListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/rounds/add/:id?",
        name: "fireroundadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRoundAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/rounds/add2/:id2?",
        name: "fireroundadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRoundAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/rounds/:id",
        name: "fireround",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireRoundItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/hazard",
        name: "firehazards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/hazard/add",
        name: "firehazardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/hazard/:id",
        name: "firehazard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/audit",
        name: "fireaudits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/audit/add",
        name: "fireauditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/audit/:id",
        name: "fireaudit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/legislation",
        name: "firerules",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/legislation/add/:id?",
        name: "fireruleadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/legislation/:id",
        name: "firerule",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/accountability",
        name: "fireobligations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/accountability/add/:id?",
        name: "fireobligationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/accountability/:id",
        name: "fireobligation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building",
        name: "firebuildings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireBuildingListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/add/:id?",
        name: "firebuildingadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireBuildingAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/floor",
        name: "firefloors",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireFloorListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/floor/add/:id",
        name: "fireflooradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireFloorAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/floor/:id",
        name: "firefloor",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireFloorItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/device",
        name: "firedevices",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireDeviceListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/device/add/:id",
        name: "firedeviceadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireDeviceAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/device/:id",
        name: "firedevice",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireDeviceItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/building/:id",
        name: "firebuilding",
        component: () => import(/* webpackChunkName: "main" */ "@/views/fire/FireBuildingItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/fire/product",
        name: "fireproducts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherProductListView.vue"),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "/sustainability/home",
        name: "sustainabilitydashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/sustainability/accountability",
        name: "obligations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_accountability"
        }
      },
      {
        path: "/sustainability/accountability/add/:id?",
        name: "obligationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_accountability"
        }
      },
      {
        path: "/sustainability/accountability/:id",
        name: "obligation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_accountability"
        }
      },
      {
        path: "/sustainability/analys",
        name: "analyss",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/analys/add/:id?",
        name: "analysadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/analys/add2/:id2?",
        name: "analysadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/analys/add3/:id/:id2",
        name: "analysadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/analys/add4/:id2?/:id3?",
        name: "analysadd4",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAdd2View.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/analys/:id",
        name: "analys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/analys2/:id",
        name: "subanalys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisSubItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/sustainability/documentation",
        name: "documentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/documentation/add",
        name: "documentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/documentation/report",
        name: "documentationreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/documentation/:id/add",
        name: "documentationchainadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationChainAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/documentation/:id/:id2",
        name: "documentationchain",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationChainItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/documentation/:id",
        name: "documentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/chain",
        name: "valuechain",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityValueChainReport.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/report",
        name: "sustainabilityreports",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityReportListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/report/add",
        name: "sustainabilityreportadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityReportAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/report/:id",
        name: "sustainabilityreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityReportItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure",
        name: "sustainabilitykeyfiguredashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/waste",
        name: "wastes",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/waste/add/:id?",
        name: "wasteadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/waste/add2/:id?",
        name: "wasteadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/waste/report",
        name: "wastereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/waste/:id",
        name: "waste",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/co2",
        name: "co2s",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2ListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/co2/add/:id?",
        name: "co2add",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/co2/add2/:id?",
        name: "co2add2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/co2/report",
        name: "co2report",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2ReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/co2/:id",
        name: "co2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2ItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/keyfigure/other",
        name: "sustainabilitykeyfigures",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/other/add/:id?",
        name: "sustainabilitykeyfigureadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/other/report/:id",
        name: "sustainabilitykeyfigurereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/other/:id",
        name: "sustainabilitykeyfigure",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/kpi3",
        name: "kpi3s",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/kpi3/list/:id",
        name: "kpi3list",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/kpi3/add/:id?",
        name: "kpi3add",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/kpi3/add/:id/:id2",
        name: "kpi3add2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/kpi3/kpi3report/:id/",
        name: "kpi3report",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/keyfigure/kpi3/:id",
        name: "kpi3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/sustainability/documentation2",
        name: "documentation2managements",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/sustainability/documentation2/add/:id?",
        name: "documentation2managementadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/sustainability/documentation2/:id",
        name: "documentation2management",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/sustainability/legislation",
        name: "rules",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/sustainability/legislation/add/:id?",
        name: "ruleadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/sustainability/legislation/:id",
        name: "rule",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/sustainability/hazard",
        name: "hazards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_hazard"
        }
      },
      {
        path: "/sustainability/hazard/add",
        name: "hazardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_hazard"
        }
      },
      {
        path: "/sustainability/hazard/:id",
        name: "hazard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_hazard"
        }
      },
      {
        path: "/sustainability/stakeholder",
        name: "concerns",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_stakeholder"
        }
      },
      {
        path: "/sustainability/stakeholder/add",
        name: "concernadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_stakeholder"
        }
      },
      {
        path: "/sustainability/stakeholder/:id",
        name: "concern",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyStakeholderItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_stakeholder"
        }
      },
      {
        path: "/sustainability/policy",
        name: "sustainabilitypolicys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_policy"
        }
      },
      {
        path: "/sustainability/policy/add",
        name: "sustainabilitypolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_policy"
        }
      },
      {
        path: "/sustainability/policy/:id",
        name: "sustainabilitypolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_policy"
        }
      },
      {
        path: "/sustainability/instructions",
        name: "sustainabilityinstructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_instructions"
        }
      },
      {
        path: "/sustainability/instructions/add",
        name: "sustainabilityinstructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_instructions"
        }
      },
      {
        path: "/sustainability/instructions/:id",
        name: "sustainabilityinstruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_instructions"
        }
      },
      {
        path: "/sustainability/routine",
        name: "sustainabilityroutines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_routine"
        }
      },
      {
        path: "/sustainability/routine/add",
        name: "sustainabilityroutineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_routine"
        }
      },
      {
        path: "/sustainability/routine/:id",
        name: "sustainabilityroutine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_routine"
        }
      },
      {
        path: "/sustainability/isorisks",
        name: "sustainabilityisorisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_iso_risk"
        }
      },
      {
        path: "/sustainability/isorisks/add",
        name: "sustainabilityisoriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_iso_risk"
        }
      },
      {
        path: "/sustainability/isorisks/:id",
        name: "sustainabilityisorisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_iso_risk"
        }
      },
      {
        path: "/sustainability/goals",
        name: "sustainabilitygoals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_goals"
        }
      },
      {
        path: "/sustainability/goals/add",
        name: "sustainabilitygoaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_goals"
        }
      },
      {
        path: "/sustainability/goals/:id",
        name: "sustainabilitygoal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_goals"
        }
      },
      {
        path: "/sustainability/supplier",
        name: "sustainabilitysuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier_control"
        }
      },
      {
        path: "/sustainability/supplier/add/:id?",
        name: "sustainabilitysupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier_control"
        }
      },
      {
        path: "/sustainability/supplier/:id",
        name: "sustainabilitysupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_supplier_control"
        }
      },
      {
        path: "/sustainability/checklists",
        name: "otherchecklists",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlChecklistListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/checklists/add/:id?",
        name: "otherchecklistadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlChecklistAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/checklists/add2/:id2?",
        name: "otherchecklistadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlChecklistAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/checklists/:id",
        name: "otherchecklist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlChecklistItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/sustainability/deviations",
        name: "sustainabilitydeviations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_deviations"
        }
      },
      {
        path: "/sustainability/deviations/add",
        name: "sustainabilitydeviationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_deviations"
        }
      },
      {
        path: "/sustainability/deviations/:id",
        name: "sustainabilitydeviation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_deviations"
        }
      },
      {
        path: "/sustainability/audit",
        name: "sustainabilityaudits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_audit"
        }
      },
      {
        path: "/sustainability/audit/add/:id?",
        name: "sustainabilityauditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_audit"
        }
      },
      {
        path: "/sustainability/audit/:id",
        name: "sustainabilityaudit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_audit"
        }
      },
      {
        path: "/sustainability/approved_supplier",
        name: "sustainabilityapprovedsuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/approved_supplier/report",
        name: "sustainabilityapprovedsupplierreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/approved_supplier/add",
        name: "sustainabilityapprovedsupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/approved_supplier/:id",
        name: "sustainabilityapprovedsupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/customer",
        name: "sustainabilitycustomers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCustomerListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/customer/add",
        name: "sustainabilitycustomeradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCustomerAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/customer/:id",
        name: "sustainabilitycustomer",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCustomerItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/product",
        name: "sustainabilityproducts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/sustainability/product/add",
        name: "sustainabilityproductadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/product/:id",
        name: "sustainabilityproduct",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/sustainability/approved_supplier/report",
        name: "sustainabilityproductreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/sustainability/ideabox",
        name: "sustainabilityideabox",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/sustainability/ideabox/add/:id?",
        name: "sustainabilityideaadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/sustainability/ideabox/add2/:id2?",
        name: "sustainabilityideaadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/sustainability/ideabox/report/",
        name: "sustainabilityideareport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/sustainability/ideabox/:id",
        name: "sustainabilityidea",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/esrs/overview",
        name: "esrsoverview",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityESRSOverview.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/isorisks",
        name: "esrsisorisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_iso_risk"
        }
      },
      {
        path: "/esrs/isorisks/add",
        name: "esrsisoriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_iso_risk"
        }
      },
      {
        path: "/esrs/isorisks/:id",
        name: "esrsisorisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_iso_risk"
        }
      },
      {
        path: "/esrs/goals",
        name: "esrsgoals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_goals"
        }
      },
      {
        path: "/esrs/goals/add",
        name: "esrsgoaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_goals"
        }
      },
      {
        path: "/esrs/goals/:id",
        name: "esrsgoal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_goals"
        }
      },
      {
        path: "/esrs/documentation",
        name: "esrsdocumentations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/documentation/add",
        name: "esrsdocumentationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/documentation/report",
        name: "esrsdocumentationreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/documentation/:id/add",
        name: "esrsdocumentationchainadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationChainAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/documentation/:id/:id2",
        name: "esrsdocumentationchain",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationChainItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/documentation/:id",
        name: "esrsdocumentation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityDocumentationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/stakeholder",
        name: "esrsconcerns",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_stakeholder"
        }
      },
      {
        path: "/esrs/stakeholder/add",
        name: "esrsconcernadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_stakeholder"
        }
      },
      {
        path: "/esrs/stakeholder/:id",
        name: "esrsconcern",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyStakeholderItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_stakeholder"
        }
      },
      {
        path: "/esrs/analyses",
        name: "esrsanalysses",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisEsrsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys",
        name: "esrsanalyss",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys/add/:id?",
        name: "esrsanalysadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys/add2/:id2?",
        name: "esrsanalysadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys/add3/:id/:id2",
        name: "esrsanalysadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys/add4/:id2?/:id3?",
        name: "esrsanalysadd4",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisAdd2View.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys/:id",
        name: "esrsanalys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys/:id/risk",
        name: "esrsanalys2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/analys2/:id",
        name: "esrssubanalys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAnalysisSubItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/report",
        name: "esrsreports",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityReportListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/report/add",
        name: "esrsreportadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityReportAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/report/:id",
        name: "esrsreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityReportItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure",
        name: "esrskeyfiguredashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/waste",
        name: "esrswastes",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/waste/add/:id?",
        name: "esrswasteadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/waste/add2/:id?",
        name: "esrswasteadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/waste/report",
        name: "esrswastereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/waste/:id",
        name: "esrswaste",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityWasteItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/co2",
        name: "esrsco2s",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2ListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/co2/add/:id?",
        name: "esrsco2add",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/co2/add2/:id?",
        name: "esrsco2add2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/co2/report",
        name: "esrsco2report",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2ReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/co2/:id",
        name: "esrsco2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityCO2ItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_checklists"
        }
      },
      {
        path: "/esrs/keyfigure/other",
        name: "esrskeyfigures",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/other/add/:id?",
        name: "esrskeyfigureadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/other/report/:id",
        name: "esrskeyfigurereport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/other/:id",
        name: "esrskeyfigure",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKeyfigureItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/kpi3",
        name: "esrskpi3s",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/kpi3/list/:id",
        name: "esrskpi3list",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/kpi3/add/:id?",
        name: "esrskpi3add",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/kpi3/add/:id/:id2",
        name: "esrskpi3add2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3AddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/kpi3/kpi3report/:id/",
        name: "esrskpi3report",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/keyfigure/kpi3/:id",
        name: "esrskpi3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityKPI3ItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_documentation"
        }
      },
      {
        path: "/esrs/documentation2",
        name: "esrsdocumentation2managements",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/esrs/documentation2/add/:id?",
        name: "esrsdocumentation2managementadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/esrs/documentation2/:id",
        name: "esrsdocumentation2management",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/esrs/valuechain",
        name: "esrsvaluechains",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityValueChainListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/esrs/valuechain/add/:id?",
        name: "esrsvaluechainadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityValueChainAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/esrs/valuechain/:id",
        name: "esrsvaluechain",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityValueChainItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/esrs/materiality",
        name: "materialitys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityMaterialityListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_tips"
        }
      },
      {
        path: "/esrs/materiality/add/:id?",
        name: "materialityadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityMaterialityAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_tips"
        }
      },
      {
        path: "/esrs/materiality/:id",
        name: "materiality",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityMaterialityItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_tips"
        }
      },
      {
        path: "/esrs/audit",
        name: "esrsaudits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_audit"
        }
      },
      {
        path: "/esrs/audit/add/:id?",
        name: "esrsauditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_audit"
        }
      },
      {
        path: "/esrs/audit/:id",
        name: "esrsaudit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_audit"
        }
      },
      {
        path: "/esrs/approved_supplier",
        name: "esrsapprovedsuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/esrs/approved_supplier/report",
        name: "esrsapprovedsupplierreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/esrs/approved_supplier/add",
        name: "esrsapprovedsupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/esrs/approved_supplier/:id",
        name: "esrsapprovedsupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_approved_supplier"
        }
      },
      {
        path: "/esrs/product",
        name: "esrsproducts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/esrs/product/add",
        name: "esrsproductadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/esrs/product/:id",
        name: "esrsproduct",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/esrs/datapoint",
        name: "esrsdatapoints",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityEsrsDatapointListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/datapoint/add",
        name: "esrsdatapointadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityEsrsDatapointAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/esrs/datapoint/:id",
        name: "esrsdatapoint",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityEsrsDatapointItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "reports"
        }
      },
      {
        path: "/quality/home",
        name: "qualitydashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDashboardView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_dashboard"
        }
      },
      {
        path: "/quality/process_map",
        name: "processmaps",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProcessmapListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_process_map"
        }
      },
      {
        path: "/quality/process_map/add/:id?",
        name: "processmapadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProcessmapAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_process_map"
        }
      },
      {
        path: "/quality/process_map/workprocess",
        name: "workprocesses",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityWorkProcessListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/process_map/workprocess2",
        name: "workprocesses2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityWorkProcessMeetingView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/businessprocess",
        name: "businessprocess",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityWorkProcessMeetingView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/process_map/workprocess/add/:id?",
        name: "workprocessadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityWorkProcessAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/process_map/workprocess/addlist",
        name: "workprocessaddlist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityWorkProcessListAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/process_map/workprocess/:id",
        name: "workprocess",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityWorkProcessItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/process_map/:id",
        name: "processmap",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProcessmapItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_process_map"
        }
      },
      {
        path: "/quality/documentation",
        name: "documentationmanagements",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/documentation/add/:id?",
        name: "documentationmanagementadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/documentation/:id",
        name: "documentationmanagement",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDocumentationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_documentation"
        }
      },
      {
        path: "/quality/approved_supplier",
        name: "approvedsuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/report",
        name: "approvedsupplierreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/add/:id?",
        name: "approvedsupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/inquirys",
        name: "suppliersurveys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityInquiryListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/inquirys/add/:id?",
        name: "suppliersurveyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityInquiryAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/inquirys/add2/:id?",
        name: "suppliersurveyadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityInquiryAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/inquirys/:id",
        name: "suppliersurvey",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityInquiryItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/approved_supplier/:id",
        name: "approvedsupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityApprovedSupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/customer",
        name: "customers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCustomerListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/customer/add/:id?",
        name: "customeradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCustomerAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/customer/:id",
        name: "customer",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCustomerItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/crm",
        name: "crms",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCRMListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/crm/add/:id?",
        name: "crmadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCRMAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/crm/addList/",
        name: "crmadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCRMListAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },
      {
        path: "/quality/crm/:id",
        name: "crm",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityCRMItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_approved_supplier"
        }
      },


      {
        path: "/quality/product",
        name: "qualityproducts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/quality/product/report",
        name: "qualityproductreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/quality/product/add",
        name: "qualityproductadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/quality/product/:id",
        name: "qualityproduct",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityProductItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_products"
        }
      },
      {
        path: "/quality/audit",
        name: "audits",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_audit"
        }
      },
      {
        path: "/quality/audit/add",
        name: "auditadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_audit"
        }
      },
      {
        path: "/quality/audit/:id",
        name: "audit",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityAuditItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_audit"
        }
      },
      {
        path: "/quality/surveys",
        name: "customersurveys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualitySurveyListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/surveys/add/:id?",
        name: "customersurveyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualitySurveyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/surveys/add2/:id?",
        name: "customersurveyadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualitySurveyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/surveys/:id",
        name: "customersurvey",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualitySurveyItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/externalsurveys/add/:id?",
        name: "externalsurveyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityExternalSurveyBundleAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/externalsurveys/report/:id",
        name: "externalsurveyreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityExternalSurveyBundleReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/externalsurveys/:id",
        name: "externalsurvey",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityExternalSurveyBundleItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_customer_surveys"
        }
      },
      {
        path: "/quality/checklists",
        name: "forms",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityFormListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/checklists/add/:id?",
        name: "formadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityFormAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/checklists/add2/:id2?",
        name: "formadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityFormAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/checklists/add3/:id/:id2",
        name: "formadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityFormAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/checklists/:id",
        name: "form",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityFormItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/logbook/add2/:id2?",
        name: "logbookadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityLogBookAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/logbook/add3/:id/:id2",
        name: "logbookadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityLogBookAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/logbook/:id",
        name: "logbook",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityLogBookItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_checklists"
        }
      },
      {
        path: "/quality/goalforms/add2/:id2?",
        name: "goalformadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityGoalFormAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/quality/goalforms/:id",
        name: "goalform",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityFormItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "policy_goals"
        }
      },
      {
        path: "/quality/deviations",
        name: "deviations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_deviations"
        }
      },
      {
        path: "/quality/deviations/add/:id?",
        name: "deviationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_deviations"
        }
      },
      {
        path: "/quality/deviations/add2/:id2",
        name: "deviationadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_deviations"
        }
      },
      {
        path: "/quality/deviations/add3/:id3",
        name: "deviationadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_deviations"
        }
      },
      {
        path: "/quality/deviations/report/:id?",
        name: "deviationreport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_deviations"
        }
      },
      {
        path: "/quality/deviations/:id",
        name: "deviation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityDeviationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_deviations"
        }
      },
      {
        path: "/quality/subrisks/",
        name: "subrisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityRiskListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/quality/subrisks/add/:id?",
        name: "subriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/quality/subrisks/add2/:id2?/:id?",
        name: "subriskadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityRiskAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/quality/subrisks/:id",
        name: "subrisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/quality/QualityRiskItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/quality/policy",
        name: "qualitypolicys",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_policy"
        }
      },
      {
        path: "/quality/policy/add",
        name: "qualitypolicyadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_policy"
        }
      },
      {
        path: "/quality/policy/:id",
        name: "qualitypolicy",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyPolicyItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_policy"
        }
      },
      {
        path: "/quality/instructions",
        name: "qualityinstructions",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_instructions"
        }
      },
      {
        path: "/quality/instructions/add",
        name: "qualityinstructionadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_instructions"
        }
      },
      {
        path: "/quality/instructions/:id",
        name: "qualityinstruction",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyInstructionItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_instructions"
        }
      },
      {
        path: "/quality/routine",
        name: "qualityroutines",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_routine"
        }
      },
      {
        path: "/quality/routine/add",
        name: "qualityroutineadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_routine"
        }
      },
      {
        path: "/quality/routine/:id",
        name: "qualityroutine",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyRoutineItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_routine"
        }
      },
      {
        path: "/quality/goals",
        name: "qualitygoals",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_goals"
        }
      },
      {
        path: "/quality/goals/add",
        name: "qualitygoaladd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_goals"
        }
      },
      {
        path: "/quality/goals/:id",
        name: "qualitygoal",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyGoalsItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_goals"
        }
      },
      {
        path: "/quality/isorisks",
        name: "qualityisorisks",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_iso_risk"
        }
      },
      {
        path: "/quality/isorisks/add",
        name: "qualityisoriskadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_iso_risk"
        }
      },
      {
        path: "/quality/isorisks/:id",
        name: "qualityisorisk",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyIsoRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_iso_risk"
        }
      },
      {
        path: "/quality/supplier",
        name: "qualitysuppliers",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_supplier_control"
        }
      },
      {
        path: "/quality/supplier/add/:id?",
        name: "qualitysupplieradd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_supplier_control"
        }
      },
      {
        path: "/quality/supplier/add2/:id2",
        name: "qualitysupplieradd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_supplier_control"
        }
      },
      {
        path: "/quality/supplier/:id",
        name: "qualitysupplier",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicySupplierItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_supplier_control"
        }
      },
      {
        path: "/quality/hazard",
        name: "qualityhazards",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_hazard"
        }
      },
      {
        path: "/quality/hazard/add",
        name: "qualityhazardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_hazard"
        }
      },
      {
        path: "/quality/hazard/:id",
        name: "qualityhazard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityRiskItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_hazard"
        }
      },
      {
        path: "/quality/legislation",
        name: "qualityrules",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/quality/legislation/add/:id?",
        name: "qualityruleadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityLegislationAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/quality/legislation/:id",
        name: "qualityrule",
        component: () => import(/* webpackChunkName: "main" */ "@/views/operations/OperationLegislationItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "sustainability_legislation"
        }
      },
      {
        path: "/quality/stakeholder",
        name: "qualityconcerns",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_stakeholder"
        }
      },
      {
        path: "/quality/stakeholder/add",
        name: "qualityconcernadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityStakeholderAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_stakeholder"
        }
      },
      {
        path: "/quality/stakeholder/:id",
        name: "qualityconcern",
        component: () => import(/* webpackChunkName: "main" */ "@/views/policy/PolicyStakeholderItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_stakeholder"
        }
      },
      {
        path: "/quality/accountability",
        name: "qualityobligations",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_accountability"
        }
      },
      {
        path: "/quality/accountability/add/:id?",
        name: "qualityobligationadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_accountability"
        }
      },
      {
        path: "/quality/accountability/:id",
        name: "qualityobligation",
        component: () => import(/* webpackChunkName: "main" */ "@/views/sustainability/SustainabilityAccountabilityItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "quality_accountability"
        }
      },
      {
        path: "/quality/ideabox",
        name: "qualityideabox",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/quality/ideabox/add/:id?",
        name: "qualityideaadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/quality/ideabox/add2/:id2?",
        name: "qualityideaadd2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/quality/ideabox/report/",
        name: "qualityideareport",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxReportView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/quality/ideabox/:id",
        name: "qualityidea",
        component: () => import(/* webpackChunkName: "main" */ "@/views/employees/EmployeeIdeaBoxItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "employees_ideabox"
        }
      },
      {
        path: "/other/",
        name: "otherdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/visitors",
        name: "visitors",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherVisitorsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/gdpr",
        name: "gdprlist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherGDPRListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/gdpr/:id",
        name: "gdpritem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherGDPRItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/ice",
        name: "icelist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherICEListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/ice/:id",
        name: "iceitem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherICEItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/training",
        name: "traininglist",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherTrainingListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/other/training/:id",
        name: "trainingitem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherTrainingItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/",
        name: "workplacedashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/equipment",
        name: "equipmentdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceEquiptmentList.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/equipment/add",
        name: "equipmentproductadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceEquiptmentAdd.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/equipment/:id",
        name: "equipmentproduct",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceEquiptmentItem.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/measurements",
        name: "workplacemeasurements",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceMeasurementsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/measurements/light",
        name: "workplacelight",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceLightView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/measurements/noise",
        name: "workplacenoise",
        component: () => import(/* webpackChunkName: "main" */ "@/views/workplace/WorkplaceNoiseView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals",
        name: "chemdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemDashboard.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/products",
        name: "chemproducts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemProductList.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/products/add",
        name: "chemproductadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemProductAdd.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/products/:id",
        name: "chemproduct",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemProductItem.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/purchases",
        name: "chempurchases",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemPurchaseList.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/reports",
        name: "chemreports",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemReportView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/ads",
        name: "chemads",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemAdList.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/workplace/chemicals/ads/:id",
        name: "chemad",
        component: () => import(/* webpackChunkName: "main" */ "@/views/chem/ChemAdItem.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/control",
        name: "controldashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/control/overview",
        name: "controloverview",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlActivitiesOverview.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_gantt"
        }
      },
      {
        path: "/control/documents",
        name: "controldocuments",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlDocumentsOverview.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_myactivities"
        }
      },
      {
        path: "/control/activities/add",
        name: "controlactivityadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlActivitiesAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_myactivities"
        }
      },
      {
        path: "/control/activities/:id",
        name: "controlactivity",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlActivitiesItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_activities"
        }
      },
      {
        path: "/control/myactivities",
        name: "controlmyactivities",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlMyActivitiesView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_myactivities"
        }
      },
      {
        path: "/control/activities",
        name: "controlactivities",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlActivitiesListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_activities"
        }
      },
      {
        path: "/control/reports",
        name: "controlreports",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlReportView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/control/plans",
        name: "controlplans",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlPlanListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/plans/add/:id?",
        name: "controlplanadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlPlanAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/plans/add3/:id3?",
        name: "controlplanadd3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlPlanAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/plans/:id",
        name: "controlplan",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlPlanItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/employee",
        name: "controlemployees",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlEmployeeListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/employee/add",
        name: "controlemployeeadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlEmployeeAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/employee/:id",
        name: "controlemployee",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlEmployeeItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_plans"
        }
      },
      {
        path: "/control/events/",
        name: "controlevents",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlEventListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "control_activities"
        }
      },
      {
        path: "/other/tips",
        name: "othertips",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlTipListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_tips"
        }
      },
      {
        path: "/other/tips/add",
        name: "othertipadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlTipAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_tips"
        }
      },
      {
        path: "/other/tips/:id",
        name: "othertip",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlTipItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_tips"
        }
      },
      {
        path: "/other/chem",
        name: "otherchems",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherChemListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_chem"
        }
      },
      {
        path: "/other/chem/add",
        name: "otherchemadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherChemAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_chem"
        }
      },
      {
        path: "/other/chem/:id",
        name: "otherchem",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherChemItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_chem"
        }
      },
      {
        path: "/other/product",
        name: "otherproducts",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherProductListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_product"
        }
      },
      {
        path: "/other/product/add/:id?",
        name: "otherproductadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherProductAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_product"
        }
      },
      {
        path: "/other/product/:id",
        name: "otherproduct",
        component: () => import(/* webpackChunkName: "main" */ "@/views/other/OtherProductItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_product"
        }
      },
      {
        path: "/other/workplaces",
        name: "otherworkplaces",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlWorkplaceListView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_workplaces"
        }
      },
      {
        path: "/other/workplaces/add/:id?",
        name: "otherworkplaceadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlWorkplaceAddView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_workplaces"
        }
      },
      {
        path: "/other/workplaces/:id",
        name: "otherworkplace",
        component: () => import(/* webpackChunkName: "main" */ "@/views/control/ControlWorkplaceItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "other_workplaces"
        }
      },
      {
        path: "/reports",
        name: "reportdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/reports/ReportDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/DocumentsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/help",
        name: "helps",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/HelpListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/help/:id",
        name: "help",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/HelpItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/search/:string?",
        name: "searchs",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/SearchListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/search/examples",
        name: "searchexamples",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/SearchExamplesListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/support",
        name: "supports",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/SupportListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/support/add",
        name: "supportadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/SupportAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/support/:id",
        name: "support",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/SupportItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/print1",
        name: "settingsprint1",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsPrint1View.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/print2",
        name: "settingsprint2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsPrint2View.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/print3",
        name: "settingsprint3",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsPrint3View.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/dashboard/add",
        name: "settingsdashboardadd",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsDashboardAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/dashboard/:id",
        name: "settingsdashboard",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsDashboardItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/info/:id",
        name: "settingsinfo",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsInfoItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/checklists/:type",
        name: "settingschecklists",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsChecklistsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "main" */ "@/views/settings/SettingsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/questionnaire/:id?",
        name: "questionnaire",
        component: () => import(/* webpackChunkName: "main" */ "@/views/questionnaire/QuestionnaireItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "custom_questionnaires"
        }
      },
      {
        path: "/questionnaire2/:id?",
        name: "questionnaire2",
        component: () => import(/* webpackChunkName: "main" */ "@/views/questionnaire/Questionnaire2ItemView.vue"),
        meta: {
          requiresAuth: true,
          requiredModule: "custom_questionnaires"
        }
      },
      {
        path: "/readreceipt/:id?",
        name: "readreceipt",
        component: () => import(/* webpackChunkName: "main" */ "@/components/ReadReceipt/ReadReceiptItemView.vue"),
        meta: {
          requiresAuth: true
          //requiredModule: "change_info"
        }
      },
      {
        path: "/protocolrouter/:id",
        name: "protocolrouter",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/ProtocolRouter.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/inforouter/:id",
        name: "inforouter",
        component: () => import(/* webpackChunkName: "main" */ "@/views/core/InfoRouter.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/dashboard",
        name: "partnerinternaldashboard",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path: "/",
    name: "",
    component: () => import(/* webpackChunkName: "partner" */ "@/views/PartnerView.vue"), // This is a layout view
    children: [
      {
        path: "/partner/home",
        name: "partnerdashboard",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/home",
        name: "partneradsdashboard",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAdsDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products1",
        name: "partnerad1s",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds1ListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products1/add",
        name: "partnerad1add",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds1AddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products1/:id",
        name: "partnerad1",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds1ItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "/partner/ads/products2",
        name: "partnerad2s",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds2ListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products2/add",
        name: "partnerad2add",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds2AddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products2/:id",
        name: "partnerad2",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds2ItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "/partner/ads/products3",
        name: "partnerad3s",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds3ListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products3/add",
        name: "partnerad3add",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds3AddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/ads/products3/:id",
        name: "partnerad3",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerAds3ItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/help",
        name: "partnerhelp",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerHelpView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/settings",
        name: "partnersettings",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSettingsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/checklists",
        name: "partnerquestionnaires",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemChecklistsListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/checklists/:id?",
        name: "partnerquestionnaire",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemChecklistsItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/info",
        name: "partnerinfos",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemInfoListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/info/add/:type?",
        name: "partnerinfoadd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemInfoAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/info/:id",
        name: "partnerinfo",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemInfoItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/medicalcheck",
        name: "partnermedicalchecks",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemMedicalCheckListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/medicalcheck/add",
        name: "partnermedicalcheckadd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemMedicalCheckAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/medicalcheck/:id",
        name: "partnermedicalcheck",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemMedicalCheckItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/training",
        name: "partnertrainings",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemTrainingListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/training/add",
        name: "partnertrainingadd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemTrainingAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/training/:id",
        name: "partnertraining",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemTrainingItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/home",
        name: "partnercompanydashboard",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users",
        name: "partnercompanys",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users/add",
        name: "partnercompanyadd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users/:id",
        name: "partnercompany",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users/:companyId/legal",
        name: "partnerlegals",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyLegalListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users/:companyId/legal/add",
        name: "partnerlegaladd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyLegalAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users/:companyId/legal/add2",
        name: "partnerlegaladd2",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyLegalAddMultipleView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/users/:companyId/legal/:id",
        name: "partnerlegal",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyLegalItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/statistics/activitys",
        name: "partneractivitys",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyActivityListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/statistics/activitys/addplan",
        name: "partnerplanadd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyPlanAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/statistics/activitys/addanalysis",
        name: "partneranalysisadd",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyAnalysisAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/statistics/activitys/:id",
        name: "partneractivity",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyActivityItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/:recruitedCompanyId/planning/",
        name: "partnerplanning",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyPlanningListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/companys/:recruitedCompanyId/planning/:activityId",
        name: "partnerplan",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyPlanningItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/statistics/statistics",
        name: "partnerstatistics",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerCompanyStatisticsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/statistics/claims",
        name: "partnerclaims",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/partner/PartnerCompanyClaimsStatisticsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/system/design",
        name: "partnerdesign",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerSystemDesignView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/whistleblowers/settings",
        name: "partneradminwhistleblowersettings",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/WhistleblowerPartnerSettingsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/whistleblowers/reports",
        name: "partneradminwhistleblowerreports",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerWhistleblowerListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/whistleblowers/reports/:guid",
        name: "partneradminwhistleblowerreport",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerWhistleblowerItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/whistleblowers/infos",
        name: "partneradminwhistleblowerinforeports",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerWhistleblowerInfoListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/partner/whistleblowers/infos/:id",
        name: "partneradminwhistleblowerinforeport",
        component: () => import(/* webpackChunkName: "partner" */ "@/views/partner/PartnerWhistleblowerInfoItemView.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    component: () => import(/* webpackChunkName: "parent" */ "@/views/ParentView.vue"), // This is a layout view
    children: [
      {
        path: "/parent/home",
        name: "parentdashboard",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/help",
        name: "parenthelp",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentHelpView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/settings",
        name: "parentsettings",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSettingsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/checklists",
        name: "parentquestionnaires",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemChecklistsListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/checklists/:id?",
        name: "parentquestionnaire",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemChecklistsItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/info",
        name: "parentinfos",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemInfoListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/info/add",
        name: "parentinfoadd",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemInfoAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/info/:id",
        name: "parentinfo",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemInfoItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/medicalcheck",
        name: "parentmedicalchecks",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemMedicalCheckListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/medicalcheck/add",
        name: "parentmedicalcheckadd",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemMedicalCheckAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/medicalcheck/:id",
        name: "parentmedicalcheck",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemMedicalCheckItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/training",
        name: "parenttrainings",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemTrainingListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/training/add",
        name: "parenttrainingadd",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemTrainingAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/training/:id",
        name: "parenttraining",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemTrainingItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/companys/home",
        name: "parentcompanydashboard",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyDashboardView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/companys/users",
        name: "parentcompanys",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/companys/users/add",
        name: "parentcompanyadd",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyAddView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/companys/users/:id",
        name: "parentcompany",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/activitys",
        name: "parentactivitys",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyActivityListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/activitys/:id",
        name: "parentactivity",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyActivityItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/projects",
        name: "parentprojects",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyProjectListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/projects/:id",
        name: "parentproject",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyProjectItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/jobdescriptions",
        name: "parentjobdescriptions",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyJobDescriptionListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/jobdescriptions/:id",
        name: "parentjobdescription",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyJobDescriptionItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/companys/:recruitedCompanyId/planning/",
        name: "parentplanning",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyPlanningListView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/companys/:recruitedCompanyId/planning/:activityId",
        name: "parentplan",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyPlanningItemView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/statistics/claims",
        name: "parentclaims",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentCompanyStatisticsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/parent/system/design",
        name: "parentdesign",
        component: () => import(/* webpackChunkName: "parent" */ "@/views/parent/ParentSystemDesignView.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/",
    name: "",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/AdminView.vue"), // This is a layout view
    children: [
      {
        path: "/admin/home",
        name: "admindashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/DashboardView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/home",
        name: "adminchemdashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemDashboardView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/products",
        name: "adminchemproducts",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemProductListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/products/:id",
        name: "adminchemproduct",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemProductItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/substances",
        name: "adminchemsubstances",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemSubstanceListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/substances/:id",
        name: "adminchemsubstance",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemSubstanceItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/distribution",
        name: "adminchemdistributions",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemDistributionListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/distribution/:id",
        name: "adminchemdistribution",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemDistributionItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/manufactures",
        name: "adminchemmanufactures",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemManufacturesListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/chem/manufactures/:id",
        name: "adminchemmanufacture",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ChemManufacturesItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/gear/home",
        name: "admingeardashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/GearDashboardView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/gear/products",
        name: "admingears",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/GearListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/gear/products/:id",
        name: "admingear",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/GearItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/ads/home",
        name: "adminadsdashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/AdsDashboardView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/ads/products",
        name: "adminads",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/AdsListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/ads/products/:id",
        name: "adminad",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/AdsItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/ads/wellness",
        name: "adminwellness",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/WellnessListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/ads/wellness/:id",
        name: "adminwellnessitem",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/WellnessItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/home",
        name: "adminusersdashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/UsersDashboardView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/companies",
        name: "admincompanies",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompaniesListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/companies/:id",
        name: "admincompany",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompaniesItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/companies/docuements/:id",
        name: "admincompanydocuments",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompaniesDocuments.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/companies/docuements2/:id",
        name: "admincompanydocuments2",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompaniesDocuments2.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/subscriptions",
        name: "adminsubscriptions",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SubscriptionListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/subscriptionsadd",
        name: "adminsubscriptionadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SubscriptionAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/subscriptions/:id",
        name: "adminsubscription",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SubscriptionItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/users",
        name: "adminusers",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/UsersListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/users/users/:id",
        name: "adminuser",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/UsersItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/reports/home",
        name: "adminreportsdashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ReportsDashboardView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/reports/chem",
        name: "adminreportschem",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ReportsChemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/reports/ads",
        name: "adminreportsads",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ReportsAdsView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/reports/users",
        name: "adminreportsusers",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ReportsUsersView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/questionnaires",
        name: "adminquestionnaires",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/QuestionnairesListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/questionnaire/:id?",
        name: "adminquestionnaire",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/QuestionnairesItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/info/common/:type?",
        name: "admininfos",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/InfoCommonListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/info/common/:type/add",
        name: "admininfoadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/InfoCommonAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/info/common/:type/:id?",
        name: "admininfo",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/InfoCommonItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },

      {
        path: "/admin/help/",
        name: "adminhelps",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/HelpListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/help/add",
        name: "adminhelpadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/HelpAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/help/:id?",
        name: "adminhelp",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/HelpItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/support/support",
        name: "adminsupports",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SupportListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/support/support/add",
        name: "adminsupportadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SupportAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/support/support/:id?",
        name: "adminsupport",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SupportItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/competences",
        name: "admincompetences",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompetenceListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/competences/:id",
        name: "admincompetence",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompetenceItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/competencesadd",
        name: "admincompetenceadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CompetenceAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },

      {
        path: "/admin/doc/medicalchecks",
        name: "adminmedicalchecks",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/MedicalCheckListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/medicalchecks/:id",
        name: "adminmedicalcheck",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/MedicalCheckItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/medicalchecksadd",
        name: "adminmedicalcheckadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/MedicalCheckAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },

      {
        path: "/admin/doc/trainingplans",
        name: "admintrainingplans",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/TrainingPlanListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/trainingplans/:id",
        name: "admintrainingplan",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/TrainingPlanItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/trainingplansadd",
        name: "admintrainingplanadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/TrainingPlanAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },

      {
        path: "/admin/doc/threats",
        name: "adminthreats",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ThreatListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/threats/:id",
        name: "adminthreat",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ThreatItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/threatsadd",
        name: "adminthreatadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ThreatAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/opportunities",
        name: "adminopportunities",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/OpportunityListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/opportunities/:id",
        name: "adminopportunity",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/OpportunityItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/opportunitiesadd",
        name: "adminopportunityadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/OpportunityAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/presets/:id",
        name: "adminpreset",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/PresetItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/presetsadd",
        name: "adminpresetadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/PresetAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/presets",
        name: "adminpresets",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/PresetListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/activities",
        name: "adminactivities",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ActivityListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/activities/add",
        name: "adminactivityadd",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ActivityAddView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/doc/activities/:id",
        name: "adminactivity",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ActivityItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/protocols",
        name: "adminprotocols",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ProtocolsListView.vue"),
        meta: {
          requiresSuperuser: true
        }
      },
      {
        path: "/admin/protocol/:id?",
        name: "adminprotocol",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ProtocolsItemView.vue"),
        meta: {
          requiresSuperuser: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch("auth/initStore").then(() => {
    let user = store.getters["auth/user"];
    // Fix for static token in url. We should always go to the specified url if we have a login page with nextUrl set to a public url
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.hasOwnProperty("nextUrl")) {
      var mySubString = params["nextUrl"].substring(0, 17);
      if (to.name === "login" && mySubString == "/public/checklist") {
        return next();
      }
    }

    if (to.matched.some((record) => record.meta.requiresAuth || record.meta.requiresSuperuser)) {
      if (!user) {
        return next({
          path: "/login",
          query: {
            nextUrl: to.fullPath
          }
        });
      }
    }

    if (to.matched.some((record) => record.meta.requiresSuperuser)) {
      if (!user.is_superuser) {
        return next({ path: "/home" });
      }
    }

    if (to.meta.requiredModule) {
      if (!user || !user.company.hasModule(to.meta.requiredModule)) {
        return next({
          path: "home" // Redirecting to home instead of login as user might already be logged in...
        });
      }
    }

    // If user is logged in we don't let him go to login page
    if (user && to.name === "login") {
      return next({ path: "home" });
    }

    return next();
  });
});
router.beforeResolve((to, from, next) => {
  let pendingSaveChanges = store.getters["app/pendingChanges"];
  if (pendingSaveChanges) {
    const answer = window.confirm(i18n.tc("unsavedChanges"));
    if (answer) {
      store.dispatch("app/setPendingChanges", false);
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
});

export default router;
